<script lang="ts" setup>
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import { LoginPopupTab, useLoginTab } from './helpers'
  import LoginPopupDefault from './LoginPopupDefault.vue'
  import LoginPopupForget from './LoginPopupForget.vue'
  import LoginPopupRegister from './LoginPopupRegister.vue'

  defineProps<{ visible: boolean }>()
  const emits = defineEmits<{ (e: 'update:visible', bool: boolean): void }>()
  const updateVisible = (bool: boolean) => emits('update:visible', bool)

  const tabRef = useLoginTab()
</script>
<template>
  <a-modal :mask-closable="false" :visible="visible" @update:visible="updateVisible" :simple="true" width="800px">
    <div class="login-popup">
      <div class="banner">
        <div class="banner-content">
          <div class="title">互动营销内容工具平台</div>
          <div><span class="primary">300+</span>线上游戏模板，<span class="primary">100+</span>线下大屏模</div>
          <div>零代码3分钟创建开启一场活动,赋能营销场景，实现拉新、销售、促活...</div>
          <div>全链路数据跟踪，实时转化分析团队多人协作</div>
        </div>
      </div>
      <div class="content">
        <div class="close-btn" @click="updateVisible(false)">
          <svg-icon icon-name="icon-close" icon-size="20" style="cursor: pointer"></svg-icon>
        </div>
        <a-tabs :active-key="tabRef" :animation="true">
          <a-tab-pane :key="LoginPopupTab.DEFAULT"> <login-popup-default @close="updateVisible(false)" /> </a-tab-pane>
          <a-tab-pane :key="LoginPopupTab.FORGET"> <login-popup-forget /> </a-tab-pane>
          <a-tab-pane :key="LoginPopupTab.REGISTER"> <login-popup-register /> </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </a-modal>
</template>
<style lang="less" scoped>
  .login-popup {
    display: flex;
    height: 480px;

    .banner {
      box-sizing: border-box;
      width: 360px;
      padding: 64px 33px 0;
      background-image: url('@images/login/banner_login.png');
      background-position: center;
      background-size: cover;

      .banner-logo {
        height: 31px;
        margin-bottom: 25px;

        img {
          height: 100%;
        }
      }

      .banner-content {
        color: @gray;
        font-size: 12px;

        .title {
          margin-bottom: 19px;
          font-weight: 500;
          font-size: 18px;
          line-height: 16px;
        }

        & > div {
          margin-bottom: 8px;
        }

        .primary {
          color: @primary-color;
        }
      }
    }

    .content {
      flex: 1;
      box-sizing: border-box;
      padding: 32px;

      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;

        .icon {
          color: #c4c7cc;
          cursor: pointer;
        }
      }
    }

    :deep(.arco) {
      &-tabs {
        &-nav {
          display: none;
        }

        &-content {
          padding: 0;
        }
      }

      &-form {
        &-item {
          margin: 0;
        }
      }

      &-checkbox {
        &-label {
          color: @gray-2;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
</style>
