<script lang="ts" setup>
  import { VersionType } from '@/define'
  import { useRootStore } from '@/store'
  import AdvancedMeal from '@images/meal/bg_meal_advanced.png'
  import LuxuryMeal from '@images/meal/bg_meal_luxury.png'
  import StandardMeal from '@images/meal/bg_meal_standard.png'
  import TryMeal from '@images/meal/bg_meal_try.png'
  import { computed } from 'vue'
  import { showMealInfoPopup, showUserInfoPopup } from '../helpers'
  import MealInfoPopup from './MealInfoPopup.vue'

  const store = useRootStore()

  const versionLevel = computed(() => store.userInfo.versionLevel)

  const mealImg = computed(() => {
    let mealImg = ''
    switch (versionLevel.value) {
      case VersionType.TRYVERSION:
        mealImg = TryMeal
        break
      case VersionType.STANDARD:
        mealImg = StandardMeal
        break
      case VersionType.AVANCED:
        mealImg = AdvancedMeal
        break
      case VersionType.LUXURY:
        mealImg = LuxuryMeal
        break
    }
    return mealImg
  })
  const onMouseOver = () => {
    showMealInfoPopup.value = true
    showUserInfoPopup.value = false
  }
</script>
<template>
  <div class="meal-info">
    <div class="meal-wrapper" @mouseover="onMouseOver">
      <img :src="mealImg" alt="" class="meal-img" />
    </div>
    <meal-info-popup v-model:show="showMealInfoPopup"></meal-info-popup>
  </div>
</template>
<style lang="less">
  .meal-info {
    position: relative;
  }

  .meal-wrapper {
    display: flex;
    align-items: center;

    .meal-img {
      width: 80px;
      height: 30px;
    }
  }
</style>
