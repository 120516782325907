import { evType, pageType } from '@/define'
import { ev, pv } from '@/utils/report'

// 默认上报参数
const defaultParams = {
  platf: 1,
  /** 1=游戏 2=SDK */
  page_category: 3,
}

export const pageReport = (pageParam: pageType) => {
  const params = Object.assign({}, defaultParams, pageParam)
  pv(params)
}

export const eventReport = (evParam: evType) => {
  const params = Object.assign({}, defaultParams, evParam)
  ev(params)
}

export const eventReportSyng = async (evParam: evType) => {
  const params = Object.assign({}, defaultParams, evParam)
  await ev(params)
}
