import { ViteSSGContext } from 'vite-ssg'
import { setupArco } from './arco'
import { setupBaidu } from './baidu'
import { setupPinia } from './pinia'

/** 初始化启动 */
export const setupBoot = (context: ViteSSGContext) => {
  setupArco(context)
  setupPinia(context)
  setupBaidu()
}
