import { requestLogin, requestResetPassword, requestSendSmsCode } from '@/api'
import { LoginType, SendCodeType } from '@/define'
import { useRootStore } from '@/store'
import { Message } from '@arco-design/web-vue'
import { storeToRefs } from 'pinia'
import { computed, reactive } from 'vue'
import { useCountdown } from './countdown'
import { RulesType, RULE_ACCOUNT, RULE_CODE, RULE_NOT_SPACE } from './rules'

/** 登录操作逻辑 */
export const useLoginAction = () => {
  const store = useRootStore()
  const { isLogin } = storeToRefs(store)

  const loadings = reactive({
    sms: false,
    send: false,
  })

  const form = reactive({
    type: LoginType.SMS,
    account: '',
    password: '',
    smsCode: '',
  })
  const rules = computed<RulesType<typeof form>>(() => {
    return {
      account: [
        {
          required: true,
          message: '请输入手机号/邮箱',
        },
        RULE_ACCOUNT,
      ],
      password:
        form.type === LoginType.PASSWORD || form.type === LoginType.RESET
          ? [
              {
                required: true,
                message: '请输入密码',
              },
              { minLength: 6, message: '请输入至少6位的密码' },
              RULE_NOT_SPACE,
            ]
          : [],
      smsCode:
        form.type === LoginType.SMS || form.type === LoginType.RESET
          ? [
              {
                required: true,
                message: '请输入验证码',
              },
              { minLength: 6, message: '请输入正确的验证码' },
              RULE_NOT_SPACE,
              RULE_CODE,
            ]
          : [],
    }
  })

  // 提交登录信息
  const onSubmit = async () => {
    loadings.send = true
    // 重置密码
    if (form.type === LoginType.RESET) {
      const { success } = await requestResetPassword({
        account_no: form.account,
        code: form.smsCode,
        password: form.password,
      })
      if (success) {
        form.type = LoginType.PASSWORD
      }
    } else {
      // 登录
      try {
        const { success, data } = await requestLogin(
          form.type === LoginType.SMS
            ? {
                account_no: form.account,
                login_type: LoginType.SMS,
                code: form.smsCode,
              }
            : {
                account_no: form.account,
                login_type: LoginType.PASSWORD,
                password: form.password,
              }
        )
        if (success) {
          await store.setToken(data)
        } else {
          store.clearToken()
        }
      } catch (error) {
        store.clearToken()
      }
    }
    loadings.send = false
  }

  // 消息发送倒计时
  const { count, isFinished, restart } = useCountdown()
  const onSendSms = async () => {
    loadings.sms = true
    const scene = form.type === LoginType.SMS ? SendCodeType.LOGIN : SendCodeType.FORGET_PASSWORD
    const { success, msg } = await requestSendSmsCode({ account_no: form.account, scene })
    loadings.sms = false
    if (success) {
      Message.success(msg)
      restart()
    }
  }

  return {
    loadings,
    form,
    rules,
    onSubmit,
    isLogin,
    count,
    isFinished,
    onSendSms,
  }
}
