<script lang="ts" setup>
  import { RouteName } from '@/define'
  import { useRootStore } from '@/store'
  import { openLogin, openPrivacy, openUserAgreement } from '@components/popup/helpers'
  import { computed } from 'vue'

  const store = useRootStore()

  const isLogin = computed(() => store.isLogin)

  const templateLink = [
    {
      name: '互动营销模板',
      href: `${import.meta.env.VITE_DOMAIN_URL}/marketTool/hd`,
      needLogin: false,
    },
    {
      name: '大屏互动模板',
      href: `${import.meta.env.VITE_DOMAIN_URL}/marketTool/screen`,
      needLogin: false,
    },
    {
      name: 'H5模板',
      href: `${import.meta.env.VITE_DOMAIN_URL}/moretool/h5`,
      needLogin: true,
    },
    {
      name: '海报模板',
      href: `${import.meta.env.VITE_DOMAIN_URL}/moretool/design`,
      needLogin: true,
    },
    {
      name: '长页模板',
      href: `${import.meta.env.VITE_DOMAIN_URL}/moretool/lc`,
      needLogin: true,
    },
    {
      name: '表单模板',
      href: `${import.meta.env.VITE_DOMAIN_URL}/moretool/form`,
      needLogin: true,
    },
  ]

  const serveLIst = [
    {
      name: '多想异业联合',
      href: `${import.meta.env.VITE_UNION_URL}`,
    },
    {
      name: '营销学院',
      href: `${import.meta.env.VITE_DOMAIN_URL}/home/video`,
    },
    {
      name: '开放平台',
      href: `${import.meta.env.VITE_CREATOR_URL}`,
    },
  ]

  const linkTo = (link: { name: string; href: string; needLogin?: boolean }) => {
    if (link.needLogin) {
      if (isLogin.value) {
        window.location.href = link.href
      } else {
        openLogin({
          onHide: () => {
            if (isLogin.value) {
              window.location.href = link.href
            }
          },
        })
      }
    } else {
      window.location.href = link.href
    }
  }
</script>

<template>
  <div class="footer-page">
    <div class="footer">
      <div class="navigation">
        <div class="navigation-connect">
          <div class="connect-logo"></div>
          <div class="connect-msg">
            <div class="connect-qrcode">
              <img src="@images/common/footer-qrcode-max.png" />
            </div>
            <div class="connect-content">
              <div class="phone">18659212343</div>
              <div class="tips">关注或联系我们</div>
            </div>
          </div>
        </div>
        <div class="navigation-link-box">
          <div class="navigation-link">
            <div class="title">产品</div>
            <div class="content">
              <span v-for="linkItem in templateLink" :key="linkItem.name" @click="linkTo(linkItem)">{{
                linkItem.name
              }}</span>
            </div>
          </div>
          <div class="navigation-link">
            <div class="title">拓展服务</div>
            <div class="content">
              <span v-for="linkItem in serveLIst" :key="linkItem.name" @click="linkTo(linkItem)">{{
                linkItem.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a-divider :margin="30" />
      </div>
      <div class="company">
        <span><router-link :to="{ name: RouteName.ABOUT }">公司简介</router-link></span>
        <span><router-link :to="{ name: RouteName.CONTACT }">联系我们</router-link></span>
        <span @click="openUserAgreement">用户协议</span>
        <span @click="openPrivacy">隐私政策</span>
        <span><a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2021015509号-1</a></span>
        <span class="cursor-auto">©2017-2022厦门第二未来科技有限公司</span>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .footer-page {
    background: #f5f9ff;
  }

  .footer {
    width: 1280px;
    margin: 0 auto;
    padding: 56px 0 50px;

    .company {
      display: flex;
      justify-content: center;

      span,
      a {
        color: @gray-4;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        cursor: pointer;
      }

      span {
        margin-right: 40px;

        &.cursor-auto {
          cursor: auto;
        }
      }
    }

    .navigation {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: 182px;

      .navigation-connect {
        .connect-logo {
          width: 170px;
          height: 44px;
          margin-bottom: 30px;
          background-image: url('@images/common/footer-logo.png');
          background-size: cover;
        }

        .connect-msg {
          display: flex;
          align-items: center;

          .connect-qrcode {
            width: 82px;
            height: 82px;
            margin-right: 12px;
            border: 4px solid #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 #d7e0ed;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .connect-content {
            .phone {
              color: @gray-4;
              font-weight: bold;
              font-size: 30px;
              font-family: Oswald-Regular, Oswald, sans-serif;
              line-height: 45px;
            }

            .tips {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 164px;
              height: 26px;
              color: #fff;
              font-weight: 400;
              font-size: 14px;
              font-family: PingFangSC-Regular, 'PingFang SC', sans-serif;
              line-height: 20px;
              letter-spacing: 5px;
              background: #99a0ae;
            }
          }
        }
      }

      .navigation-link-box {
        display: flex;

        .navigation-link {
          span {
            cursor: pointer;
          }

          .title {
            margin-bottom: 49px;
            color: @black;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
          }

          .content {
            display: grid;
            grid-template-columns: auto auto auto;

            span {
              margin-right: 40px;
              margin-bottom: 20px;
              color: @gray-4;
              font-size: 16px;
              line-height: 22px;

              &:nth-child(3n) {
                margin-right: 0;
              }

              &:nth-last-child(1),
              &:nth-last-child(2),
              &:nth-last-child(3) {
                margin-bottom: 0;
              }
            }
          }

          &:last-child {
            margin-left: 200px;
          }
        }
      }
    }
  }

  :global(.phone-pophover) {
    color: @gray-4;
    font-weight: 600;
    font-size: 24px;
    font-family: Oswald-SemiBold, Oswald, sans-serif;
    line-height: 36px;
  }
</style>
