<script lang="ts" setup>
  import { ColorType } from '@/define'
  import { useRootStore } from '@/store'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import { useDateFormat } from '@vueuse/core'
  import { storeToRefs } from 'pinia'
  import { computed } from 'vue'

  const props = withDefaults(defineProps<{ show: boolean }>(), { show: false })
  const emits = defineEmits<{ (e: 'update:show', bool: boolean): void }>()

  const store = useRootStore()
  const { versionLevel: level, expireStatus, ...userInfo } = storeToRefs(store)

  const visible = computed({
    get() {
      return props.show
    },
    set(val: boolean) {
      emits('update:show', val)
    },
  })
  const versionsStatusForObj = computed(() => {
    const obj = {
      text: '免费版',
      class: 'try',
    }
    switch (level.value) {
      case 1:
        obj.text = '标准版'
        obj.class = 'standard'
        break
      case 2:
        obj.text = '高级版'
        obj.class = 'advanced'
        break
      case 4:
        obj.text = '豪华版'
        obj.class = 'luxury'
        break
    }
    return obj
  })

  const endDate = computed(() => useDateFormat(userInfo.groupEndDate, 'YYYY-MM-DD'))
  const expireTxt = computed(() => {
    if (level.value !== 0) {
      return expireStatus.value === 0 ? '开通' : '续费'
    } else {
      return '开通'
    }
  })
  const versionTxt = computed(() => {
    if (expireStatus.value !== 0) {
      if (level.value === 0 || level.value === 1) {
        return '标准版立享核心功能'
      } else if (level.value === 2) {
        return '高级版立享高级功能'
      } else {
        return '豪华版立享高级功能'
      }
    } else {
      if (level.value === 0) {
        return '标准版立享核心功能'
      } else {
        return '豪华版立享高级功能'
      }
    }
  })
  const mapVersion = computed(() => {
    // 免费版本显示标准版本权益
    if (level.value === 0) {
      return 1
    } else {
      // 未过期
      if (expireStatus.value === 0) {
        return 4
      } else {
        return level.value
      }
    }
  })

  const mealFunc = {
    1: [
      { title: '互动营销', subTitle: '全功能', class: 'rights-0' },
      { title: '大屏营销', subTitle: '全功能', class: 'rights-1' },
      { title: '作品数据', subTitle: '全维度', class: 'rights-2' },
    ],
    2: [
      { title: '互动营销', subTitle: '全功能', class: 'rights-0' },
      { title: '大屏营销', subTitle: '全功能', class: 'rights-1' },
      { title: '作品数据', subTitle: '全维度', class: 'rights-2' },
      { title: '子账号数量', subTitle: '1个', class: 'rights-8' },
      { title: 'BI数据中心', subTitle: '数据沉淀', class: 'rights-4' },
      { title: '账号数据统计', subTitle: '使用数据', class: 'rights-10' },
    ],
    4: [
      { title: '子账号数量', subTitle: '6个', class: 'rights-8' },
      { title: '组织结构设置', subTitle: '3级组织', class: 'rights-6' },
      { title: '企业协作空间', subTitle: '共享素材', class: 'rights-5' },
      { title: '企业协同分发', subTitle: '企业模板', class: 'rights-7' },
      { title: 'BI数据中心', subTitle: '数据沉淀', class: 'rights-4' },
      { title: '账号数据统计', subTitle: '使用数据', class: 'rights-10' },
    ],
  }

  const showPayEvent = () => {
    window.open(`${import.meta.env.VITE_DOMAIN_URL}/saasPay`, '_blank')
  }
</script>

<template>
  <div class="meal-popup" v-show="visible" @mouseover="visible = true" @mouseleave="visible = false">
    <div class="meal-title" :class="{ [versionsStatusForObj.class]: true }">
      <span class="text">{{ versionsStatusForObj.text }}</span>
      <span class="data-text" v-if="level === 0">长期有效</span>
      <template>
        <span class="time" v-if="expireStatus === 2">已到期</span>
        <span class="time" v-else>有效期至 {{ endDate.value }}</span>
      </template>
    </div>
    <div class="meal-wrap">
      <div class="meal-sub-title">
        <span class="left icon"></span>
        <span v-if="level === 4 && expireStatus === 0" class="text">已开通高级功能</span>
        <span class="text" v-else>{{ expireTxt }}{{ versionTxt }}</span>
        <span class="right icon"></span>
      </div>
      <div class="meal-func" :class="{ [versionsStatusForObj.class]: true }">
        <div class="item" v-for="(item, index) in mealFunc[mapVersion]" :key="'item' + index">
          <div :class="{ icon: true, [item.class]: true }"></div>
          <div class="text">
            <span class="item-title">{{ item.title }}</span>
            <span class="item-sub-title">{{ item.subTitle }}</span>
          </div>
        </div>
      </div>
      <div class="meal-btn">
        <base-btn
          :color="ColorType.PRIMARY"
          v-if="(expireStatus === 0 && level !== 4) || level === 0"
          @click="showPayEvent"
          >立即升级</base-btn
        >
        <base-btn :color="ColorType.PRIMARY" v-else-if="[1, 2].includes(expireStatus)" @click="showPayEvent"
          >立即续费</base-btn
        >
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @import '@styles/rights.less';

  .meal-popup {
    position: absolute;
    top: 40px;
    left: 50%;
    width: 260px;
    overflow: hidden;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    transform: translate(-50%);
    .create-rights-icon(11,40px,40px);

    .meal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      padding: 12px 16px;
      color: #7e9ed8;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.try {
        background-image: url('@images/login/try-bg.png');

        .text {
          color: #7e9ed8;
        }
      }

      &.standard {
        background-image: url('@images/login/standard-bg.png');

        .text {
          color: #2072e1;
        }
      }

      &.advanced {
        background-image: url('@images/login/advanced-bg.png');

        .text {
          color: #b36639;
        }
      }

      &.luxury {
        background-image: url('@images/login/luxury-bg.png');

        .text {
          color: #c88d14;
        }
      }

      .text {
        font-weight: bold;
        font-size: 16px;
        white-space: nowrap;
      }

      .time {
        color: #fff;
        font-size: 12px;
      }
    }

    .meal-wrap {
      padding: 20px 16px 16px;
      background-color: #fff;

      .meal-sub-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;

        .icon {
          width: 32px;
          height: 1px;
          border-radius: 1px;
        }

        .left {
          background: linear-gradient(90deg, rgb(43 132 255 / 0%) 0%, rgb(43 132 255 / 80%) 100%);
        }

        .text {
          margin: 0 4px;
          color: #313233;
          font-size: 14px;
        }

        .right {
          background: linear-gradient(270deg, rgb(43 132 255 / 0%) 0%, rgb(43 132 255 / 80%) 100%);
        }
      }

      .meal-func {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 74px;
          padding: 0;

          &:nth-child(3n + 3) {
            margin-right: 0;
            margin-bottom: 32px;
          }

          .icon {
            margin-bottom: 8px;
          }

          .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;

            .item-title {
              padding-bottom: 4px;
              color: #313233;
            }

            .item-sub-title {
              color: #f86e00;
            }
          }
        }

        &.try {
          .item {
            .text {
              .item-sub-title {
                color: #f65c53;
              }
            }
          }
        }
      }

      .meal-btn {
        .arco-btn {
          width: 228px;
          height: 40px;
          background: #2b84ff;
          border-radius: 2px;
        }
      }
    }
  }
</style>
