<script lang="ts" setup>
  import { ColorType, RouteName } from '@/define'
  import { useRootStore } from '@/store'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import { openLogin } from '@components/popup/helpers'
  import MemberIcon from '@images/common/topbar_vip_sign.gif'
  import { computed } from 'vue'
  import MealInfo from './components/MealInfo.vue'
  import TopTip from './components/TopTip.vue'
  import UserInfo from './components/UserInfo.vue'
  import { hideAllPopup } from './helpers'

  enum NavigationName {
    ROOT = '首页',
    MEMBER = '开通会员',
    CASE = '案例',
    CUSTOMIZE = '定制',
  }

  const routeLink = [
    {
      name: NavigationName.ROOT,
      href: `${import.meta.env.VITE_DOMAIN_URL}/home/index`,
    },
    {
      name: NavigationName.MEMBER,
      href: `${import.meta.env.VITE_DOMAIN_URL}/saasPay`,
      iconMark: MemberIcon,
    },
    {
      name: NavigationName.CASE,
      routeName: RouteName.CASE,
    },
    {
      name: NavigationName.CUSTOMIZE,
      routeName: RouteName.CUSTOMIZE,
    },
  ]
  const store = useRootStore()

  const isLogin = computed(() => store.isLogin)
</script>

<template>
  <div class="topbar">
    <div class="topbar-box" @mouseleave="hideAllPopup">
      <div class="left">
        <router-link :to="{ name: RouteName.HOMEPAGE }">
          <div class="logo">
            <div class="logo-img"></div>
          </div>
        </router-link>
        <div class="line" />
        <div class="router">
          <div v-for="item in routeLink" :key="item.name" class="link-item">
            <a v-if="item.href" :href="item.href">
              <span>{{ item.name }}</span>
              <img v-if="item.iconMark" :src="item.iconMark" alt="" class="icon-mark" />
            </a>
            <router-link v-else :to="{ name: item.routeName }">{{ item.name }}</router-link>
          </div>
        </div>
      </div>
      <div class="right">
        <base-btn v-if="!isLogin" class="login-btn" :color="ColorType.PRIMARY" @click="openLogin">登录/注册</base-btn>
        <div v-else class="store-info-wrap">
          <top-tip></top-tip>
          <div class="store-info">
            <meal-info></meal-info>
            <div class="split-line"></div>
            <user-info></user-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .topbar {
    width: 100%;
    height: 64px;

    .topbar-box {
      position: fixed;
      top: 0;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      height: 64px;
      padding: 0 20px;
      background: #fff;
      box-shadow: 0 1px 4px 1px rgb(21 34 50 / 8%);
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        width: 180px;
        height: 35px;

        .logo-img {
          width: 143px;
          height: 35px;
          background-image: url('@images/common/footer-logo.png');
          background-size: cover;
        }
      }

      .line {
        width: 1px;
        height: 20px;
        background: #dcdfe6;
      }

      .router {
        display: flex;
        margin-left: 28px;

        & > div {
          display: flex;
          align-items: center;
          margin-right: 28px;

          &:hover {
            a {
              color: #2b84ff;
            }
          }

          a {
            color: #1d2129;
            font-size: 14px;
            line-height: 20px;
            text-decoration: none;
          }

          img {
            height: 23px;
          }
        }

        .active {
          a {
            color: @primary-color;
          }
        }

        .link-item {
          position: relative;

          .icon-mark {
            position: absolute;
            top: -10px;
            left: 50%;
            width: 48px;
            height: 12px;
          }
        }
      }
    }

    .right {
      .login-btn {
        color: #fff;
        background-color: #2b84ff;
      }

      .store-info-wrap {
        display: flex;
        align-items: center;

        .store-info {
          display: flex;
          align-items: center;
        }

        .split-line {
          width: 1px;
          height: 20px;
          margin: 0 28px 0 20px;
          background-color: #dcdfe6;
        }

        .store-avatar-wrapper {
          width: 32px;
          height: 32px;
          border: 1px solid #dcdfe6;
          border-radius: 50%;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>
