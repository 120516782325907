import { pageReport } from '@/composable'
import { App, createApp } from 'vue'
import ConcatPopup from './ConcatPopup.vue'

let concatInstance: App<Element> | null = null
const CONCAT_ID = 'concatInstance'
let timer: NodeJS.Timeout | null = null

const useConcat = () => {
  /**
   * 挂在提示弹窗
   */
  const showConcat = () => {
    const concatDiv = document.createElement('div')
    concatDiv.setAttribute('id', CONCAT_ID)
    document.body.appendChild(concatDiv)
    concatInstance = createApp(ConcatPopup)
    concatInstance.mount(concatDiv)
    pageReport({
      page_id: 'pc_customer_service_pop',
    })
  }

  /**
   * 延时触发挂载
   * @param times 延时时长
   */
  const openConcat = (times = 60) => {
    if (timer) {
      window.clearTimeout(timer)
    }
    timer = setTimeout(() => {
      const qidianIfram = document.getElementById('_QIDIAN_WEB_IM_IFRAME_3009111074')
      const isShowQidianService = qidianIfram ? qidianIfram.style.display !== 'none' : false
      if (!isShowQidianService || !times) {
        showConcat()
      }
    }, times * 1000)
  }

  /**
   * 关闭弹窗
   */
  const closeConcat = () => {
    const node = document.getElementById(CONCAT_ID)
    if (node) {
      document.body.removeChild(node)
    }
    concatInstance?.unmount()
    const config = localStorage.getItem('concatLocalConfig')
    const concatLocalConfig = config ? JSON.parse(config) : {}
    if (!concatLocalConfig.remember) {
      openConcat()
    }
  }

  return { openConcat, closeConcat }
}

export { useConcat }
