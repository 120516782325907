import { useRootStore } from '@/store'
import { Message } from '@arco-design/web-vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { BaseWrapper, RequestConfig, RootPath, ServerCode } from '../define'

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// 请求返回统一拦截并进行路由跳转
api.interceptors.response.use(
  // 正常请求的code错误码拦截及路由跳转
  (res) => {
    const { ignoreCodeIntercept } = <RequestConfig>res.config
    if (!ignoreCodeIntercept) {
      const { code, msg } = <BaseWrapper>res.data
      if (code === ServerCode.WRONG_TOKEN) {
        Message.error(`${msg}`)
        useRootStore().clearToken()
        useRouter().push(RootPath.HOMEPAGE)
      } else if (code !== ServerCode.SUCCESS) {
        // 其他提示，纯提示
        Message.error(`${msg}`)
      }
    }
    return res
  }
)

export default api
