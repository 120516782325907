/** 根路由名 */
export enum RootPath {
  /** 主页 */
  HOMEPAGE = '/',
}

export enum RouteName {
  HOMEPAGE = 'HomePage',
  CASE = 'Case',
  CUSTOMIZE = 'Customize',
  ABOUT = 'About',
  CONTACT = 'Contact',
}
