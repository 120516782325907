import { BaseWrapper } from '@/define'
import { dataTrackingJson } from '@/define/report'
import { post } from '../helpers'

const buildFormData = (formData: FormData, data: object | string | undefined, parentKey?: string) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key as keyof typeof data], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    parentKey && formData.append(parentKey, value as string)
  }
}

const jsonToFormData = (data: dataTrackingJson) => {
  const formData = new FormData()
  buildFormData(formData, data)
  return formData
}

export const dataTracking = (data: dataTrackingJson) => {
  const domain = import.meta.env.VITE_REPORT_URL
  const url = `${domain}/gamesdk.php/Hudongonlinegamemobile/dataTracking`
  return post<BaseWrapper>({
    url: url,
    params: data,
  })
}

export const sendBeacon = (data: dataTrackingJson) => {
  const domain = import.meta.env.VITE_REPORT_URL
  const url = `${domain}/gamesdk.php/Hudongonlinegamemobile/dataTracking`
  const formData = jsonToFormData(data)
  window.localStorage.setItem('key', JSON.stringify(data))
  window.navigator.sendBeacon(url, formData)
}
