/** 服务器返回的code */
export enum ServerCode {
  /** 成功 */
  SUCCESS = 1,
  /** 授权code不能为空 */
  WRONG_CODE = 1001,
  /** 错误的参数 */
  WRONG_PARAM,
  /** 无权限 */
  NO_ACCESS,
  /** 错误的token */
  WRONG_TOKEN,
}
