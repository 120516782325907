import { Message } from '@arco-design/web-vue'
import { useRafFn, useScriptTag } from '@vueuse/core'
import { isEmpty } from 'lodash-es'
import { ref } from 'vue'

/**
 * 添加企点客服系统
 * @param url 企点客服链接
 * @param elementId 组件id，配置在企点管理后台的id。注意url跟id对应且唯一
 * @returns
 */
export const useQidianService = (
  url = 'https://wp.qiye.qq.com/qidian/3009111074/f87e072662fc1a3cc4ea343be440a221',
  elementId = 'dxhd_customer_service'
) => {
  const loading = ref(false)
  const timeout = ref(0)
  const onServiceClick = () => {
    // 添加企点客服系统
    if (isEmpty(window.globalThis.__WPA)) {
      useScriptTag(url, () => {
        const WPA = window.globalThis.__WPA
        // 注入企点客服SDK代码逻辑的判断
        WPA.ready(() => {
          const { pause } = useRafFn(() => {
            timeout.value++
            const id = WPA.inviteKfuinReadyState[0]
            if (WPA.GLOBAL_INVITE_TPL_AND_CONF[id]) {
              pause()
              loading.value = false
              document.getElementById(elementId)?.click()
            } else if (timeout.value > 10 * 1000) {
              pause()
              loading.value = false
              Message.error('无法建立客服通道，请刷新重试')
            }
          })
        })
      })
    } else {
      document.getElementById(elementId)?.click()
    }
  }
  return {
    loading,
    onServiceClick,
  }
}
