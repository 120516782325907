import { inject, InjectionKey, provide, ref, Ref } from 'vue'

export enum LoginPopupTab {
  DEFAULT,
  FORGET,
  REGISTER,
}

const TAB_KEY: InjectionKey<Ref<LoginPopupTab>> = Symbol()

export const useLoginTab = () => {
  const injectData = inject(TAB_KEY, undefined)
  if (injectData) {
    return injectData
  } else {
    const tabKey = ref<LoginPopupTab>(LoginPopupTab.DEFAULT)
    provide(TAB_KEY, tabKey)
    return tabKey
  }
}
