/** 自定义按钮类型 */
export enum BtnType {
  /** 默认 */
  DEFAULT,
  /** 朴素 */
  PLAIN,
  /** 图标 */
  ICON,
  /** 文字 */
  TEXT,
  /** 展示按钮 */
  SHOW,
}

/** 通用颜色类型 */
export enum ColorType {
  /** 蓝底白字 */
  PRIMARY = 'primary',
  /** 蓝底白字 */
  SECONDARY = 'secondary',
  /** 绿底白字 */
  SUCCESS = 'success',
  /** 黄底白字 */
  WARN = 'warn',
  /** 红底白字 */
  DANGER = 'danger',
  /** 白底蓝字 */
  WHITE = 'white',
}

/** 按钮扩展颜色 */
export enum BtnColorType {
  DEFAULT = 'default',
}

/** 消息的类型 */
export enum MessageType {
  DEFAULT,
  INFO,
  SUCCESS,
  WARN,
  DANGER,
}

/** 组件对齐类型 */
export enum AlignType {
  RIGHT,
  CENTER,
  LEFT,
}

/** 上传格式类型 */
export enum UploadType {
  PNG,
  JPG,
  GIF,
  MP4,
  PSD,
  ZIP,
  RAR,
  SEVEN_ZIP,
}

export enum VersionType {
  TRYVERSION, // 试用版
  STANDARD, // 标准版
  AVANCED, // 高级版
  LUXURY = 4, // 豪华版
}
