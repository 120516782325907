<script lang="ts" setup>
  import { requestCheckAccount } from '@/api'
  import { pageReport, useLoginAction } from '@/composable'
  import { ColorType, LoginType } from '@/define'
  import { getAssetsFile, isEmail, isMobile } from '@/utils'
  import { Message } from '@arco-design/web-vue'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import { openPrivacy, openUserAgreement } from '@components/popup/helpers'
  import { computed, ref, watch } from 'vue'

  const emits = defineEmits<{ (e: 'close'): void }>()
  const protocalCheck = ref(false)

  const validateTrigger: ('blur' | 'change' | 'input')[] = ['blur']

  const { loadings, form, rules, onSubmit, isLogin, count, isFinished, onSendSms } = useLoginAction()

  watch(
    () => form.type,
    (val) => {
      pageReport({
        page: 'pc_login',
        parm: val,
      })
    },
    { immediate: true }
  )

  const onSubmitSuccess = async () => {
    if (form.type !== LoginType.RESET && !protocalCheck.value) {
      Message.error('请阅读并同意用户协议和隐私政策')
      return
    }
    await onSubmit()
    if (isLogin.value) {
      emits('close')
    }
  }

  const forgetPassword = () => {
    form.type = LoginType.RESET
    form.smsCode = ''
    form.password = ''
  }

  const goLogin = () => {
    form.type = LoginType.PASSWORD
  }

  const checkIcon = computed(() => getAssetsFile(protocalCheck.value ? 'login/checked.png' : 'login/unchecked.png'))

  const password = ref(true)

  const onAccountBlur = async () => {
    if (isMobile(form.account) || isEmail(form.account)) {
      const { success, data } = await requestCheckAccount({
        account_no: form.account,
      })
      if (success) {
        protocalCheck.value = data.have_registered === 1
      }
    }
  }
</script>

<template>
  <div class="login-default">
    <div class="title"></div>

    <a-form class="mt-4" :model="form" layout="vertical" size="large" :rules="rules" @submit-success="onSubmitSuccess">
      <a-form-item v-if="form.type !== LoginType.RESET" field="type">
        <a-radio-group v-model="form.type" type="button">
          <a-radio :value="LoginType.SMS">短信验证登录</a-radio>
          <a-radio :value="LoginType.PASSWORD">账号密码登录</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item field="account" hide-asterisk :validate-trigger="validateTrigger">
        <a-input v-model="form.account" placeholder="请输入手机号/邮箱" allow-clear @blur="onAccountBlur" />
      </a-form-item>

      <a-form-item
        v-if="form.type === LoginType.SMS || form.type === LoginType.RESET"
        field="smsCode"
        hide-asterisk
        content-class="space-x-3"
        :validate-trigger="validateTrigger"
      >
        <a-input v-model="form.smsCode" placeholder="请输入验证码" allow-clear />
        <base-btn
          :disabled="!isFinished"
          :loading="loadings.sms"
          size="medium"
          style="height: 36px; margin-left: 8px"
          @click="onSendSms"
          class="verify-btn"
          >{{ isFinished ? '获取验证码' : `${count} s` }}</base-btn
        >
      </a-form-item>

      <a-form-item
        v-if="form.type === LoginType.PASSWORD || form.type === LoginType.RESET"
        field="password"
        hide-asterisk
        :validate-trigger="validateTrigger"
      >
        <a-input v-model="form.password" placeholder="请输入密码" allow-clear :type="password ? 'password' : 'text'">
        </a-input>
      </a-form-item>

      <div v-if="form.type !== LoginType.RESET" class="forget-password">
        <span class="cursor-pointer" @click="forgetPassword">忘记密码?</span>
      </div>

      <a-form-item>
        <base-btn html-type="submit" long :loading="loadings.send" :color="ColorType.PRIMARY">{{
          form.type === LoginType.SMS ? '新用户注册/已有账号登录' : form.type === LoginType.PASSWORD ? '登录' : '重置'
        }}</base-btn>
      </a-form-item>
    </a-form>

    <div class="tips-bottom">
      <div v-if="form.type !== LoginType.RESET" class="protocal">
        <img :src="checkIcon" @click="protocalCheck = !protocalCheck" />
        <div class="protocal-text">
          请阅读并同意<span class="primary-font cursor-pointer" @click="openUserAgreement">《用户服务协议》</span
          >和<span class="primary-font cursor-pointer" @click="openPrivacy">《隐私政策》</span>
        </div>
      </div>
      <div v-else class="go-login">
        <span class="cursor-pointer"
          >想起密码？去<span class="login primary-font cursor-pointer" @click="goLogin">登录</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .login-default {
    .title {
      width: 132px;
      height: 31px;
      margin-bottom: 16px;
      background-image: url('@images/common/logo.png');
      background-size: cover;
    }

    :deep(.arco-form-item) {
      margin-bottom: 10px !important;
    }

    :deep(.arco-input-wrapper) {
      position: relative;
      padding-right: 0;
      padding-left: 0;
    }

    :deep(.arco-input) {
      padding-right: 12px;
      padding-left: 12px;
    }

    :deep(.arco-input-clear-btn) {
      position: absolute;
      right: 12px;
    }

    .verify-btn {
      width: 110px;
    }

    .forget-password {
      margin-bottom: 24px;
      color: @gray-1;
      font-size: 12px;
      text-align: right;
    }

    .tips-bottom {
      margin-top: 32px;
      color: @gray-1;
      font-size: 12px;
      text-align: center;

      .protocal {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          cursor: pointer;
        }

        .protocal-text {
          color: @gray-1;
        }
      }

      .go-login {
        .login {
          text-decoration: underline;
        }
      }
    }

    .primary-font {
      color: @primary-color;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
</style>
