import { AlignType } from '@/define'
import { isEmpty } from 'lodash-es'

/**
 * 获取对应对齐方式的按钮集合
 * @param btns 按钮集合
 * @param align 对齐方式，默认右对齐
 * @returns
 */
export const getAlignBtns = <T extends { align?: AlignType }>(btns: T[], align: AlignType = AlignType.RIGHT) =>
  btns.filter((btn) => {
    if (align === AlignType.RIGHT && isEmpty(btn.align)) {
      return true
    } else {
      btn.align === align
    }
  })
