import { createPinia } from 'pinia'
import { ViteSSGContext } from 'vite-ssg'
import { useRootStore } from '../store'

export const setupPinia = ({ app, initialState, router }: ViteSSGContext) => {
  const pinia = createPinia()
  app.use(pinia)

  if (import.meta.env.SSR) initialState.pinia = pinia.state.value
  else pinia.state.value = initialState.pinia || {}

  router.beforeEach((to, from, next) => {
    const store = useRootStore(pinia)
    store.initialize()
    next()
  })
}
