import { RouteRecordRaw } from 'vue-router'
import { RootPath, RouteName } from '../define'
import BaseLayout from '../display/layouts/BaseLayout.vue'

export default <RouteRecordRaw[]>[
  {
    path: RootPath.HOMEPAGE,
    component: BaseLayout,
    children: [
      {
        path: '',
        name: RouteName.HOMEPAGE,
        component: () => import('@views/root/HomePage.vue'),
      },
      {
        path: 'case',
        name: RouteName.CASE,
        component: () => import('@views/case/CasePage.vue'),
      },
      {
        path: 'customize',
        name: RouteName.CUSTOMIZE,
        component: () => import('@views/customize/CustomizePage.vue'),
      },
      {
        path: 'about',
        name: RouteName.ABOUT,
        component: () => import('@views/about/AboutPage.vue'),
      },
      {
        path: 'contact',
        name: RouteName.CONTACT,
        component: () => import('@views/contact/ContactPage.vue'),
      },

      // SEO意义不大，跳转到内容引擎页
      /* {
        path: 'member',
        component: () => import('@views/member/MemberPage.vue'),
      }, */
    ],
  },
]
