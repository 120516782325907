/** 官网页跳转内容引擎类型 */
export enum LinkToHomeType {
  ROOTBANER = 1,
  COMPETENCE = 2,
  FOOTERGUIDE = 3,
}

/** 打开客服弹窗类型 */
export enum OpenServiceType {
  SLIDER = 1,
  CONCATPOPUP = 2,
}

export type status = 0 | 1 | 2
/**
 * 数据埋点公共参数
 */
export type publicReport = {
  /** 设备类型,手 机:phone,平 板:ipad,PC:pc,互联 网电视:tv,wap:wap  */
  device_type?: string
  /** 设备的型号，如小米5、iphone8 */
  mtype?: string
  /** 制造厂商,如“apple” “samsung” “huawei“ */
  dev_make?: string
  /** 通过设备的IMEI、IDFV、网页cookies等生成的唯一标识 */
  dev_id?: string
  /** android */
  adid?: string
  /** 设备的IMEI编号，如果能获取到 */
  imei?: string
  /** mac */
  mac?: string
  /**
   * 操作系统平台
   * 1=android
   * 2=ios
   * 3=window
   * 4=mac
   */
  os?: string
  /** 设备的操作系统版本 */
  os_ver?: string
  /**
   * 设备的网络连接状态
   * 1=wifi
   * 2=蜂窝网络
   * 0=无网络
   */
  net?: unknown

  /** 经纬度 111,222 */
  coordinate?: string
  /** 国家 */
  country?: string
  /** 省 */
  province?: string
  /** 城市 */
  city?: string
  /** 状态 1=进入 2=成功 0=退出 */
  status?: status
  /** 停留时长 */
  stay_time?: string | number
  /** 浏览进度 页面显示过的最大高度/页面的有效高度 */
  progress?: number
  /** 活动id */
  active_id?: string | number
  /** 渠道id */
  active_from?: string | number
}
declare type commonType = {
  /**
     *  取埋点上报时当前的平台
     *  1=内容引擎PC端
        2=互动游戏
        3=大屏线下游戏
        4=大屏线上游戏
        5=互动小程序
        6=海报小程序
        7=H5小程序
        8=场控端小程序
        9=参与者端小程序
     */

  platf?: string
  /** 活动id */
  active_id?: string | number
  /** 渠道id */
  active_from?: string | number
  /** 自定义参数 */
  parm?: string | number
  /** 经纬度 111,222 */
  coordinate?: string
  /** 用户微信openid */
  wechat_uid?: string
}
/** alpha 测试开发环境 pro 正式环境 */
export type env = 'alpha' | 'pro' | 'operate' | 'bvt'
/**
 * 事件埋点入参
 * - {@link commonType}
 */
export type evType = commonType & {
  /** 事件id */
  event_id?: string
  /** 事件对象类型 */
  obj_type?: string | number
  /** 事件对象ID */
  obj_id?: string | number
  /** 当前页面触发页面 */
  f_page?: string
  /** 当前页面触发ID */
  f_page_id?: string
  /** 当前页面位置 */
  f_page_location?: string
  /** 联合活动 */
  custom_value?: {
    /** 类型 1: 奖品 */
    type_value: number
    /** 奖品id */
    field1: string
    /** 奖品归属活动id */
    field2: string
  }
}

/**
 * 页面埋点入参
 */
export type pageType = commonType & {
  /** 1=进入 2=成功 0=退出 */
  status?: status
  /** 时长  从进入到退出期间，在页面停留的时长 单位：秒，保留3位小数 退出时上报 */
  stay_time?: string | number
  /** 页面显示过的最大高度/页面有效高度 限定部分页面，退出时上报 */
  progress?: number
  /** 1=游戏 2=抽奖 */
  page_category?: number | string
  /** 页面 */
  page?: string
  /** 表示当前PV的页面标识 */
  page_id?: string
  /** 来路页面 */
  f_page?: string
  /** 来路页面位置 */
  f_page_location?: string
}
/**
 * 网络状态对象
 */
export type connection = {
  effectiveType: string
}

/**
 * 埋点接口请求json数据格式
 */
export type dataTrackingJson = {
  common: {
    device: {
      platf?: string
      dev_id?: string
      imei?: string
      mac?: string
      adid?: string
      mtype?: string
      os?: string
      os_ver?: string
      device_type?: string
      dev_make?: string
    }
    account?: {
      wechat_uid?: string
    }
    scene?: {
      net?: unknown
      coordinate?: string
    }
  }
  action: {
    status?: status
    stay_time: string | number
    progress: string | number
  }
  start_source: {
    active_id: string | number
    active_from: string | number
  }
  identify: {
    page_category: string | number
    page: string
    page_id: string
    parm: string | number
  }
  referer: {
    f_page: string
    f_page_id: string
    f_page_location: string
  }
  event: {
    event_id: string
    obj_type: string | number
    obj_id: string | number
    parm: string | number
    custom_value?: {
      type_value: number
      field1: string
      field2: string
    }
  }
}
