import { isEmail, isMobile, isVerificationCode } from '@/utils'
import { FieldRule } from '@arco-design/web-vue'

export type RulesType<T> = {
  [P in keyof T]?: FieldRule | FieldRule[]
}

/** 不能有前后空格的验证规则 */
export const RULE_NOT_SPACE: FieldRule = {
  match: /^[^ ].+[^ ]$|^\S+$/gm,
  message: '请不要包含前后空格',
}

/** 登录账号验证规则 */
export const RULE_ACCOUNT: FieldRule = {
  validator: (value, callback) => {
    if (!isEmail(value) && !isMobile(value)) {
      callback('请输入正确的手机号码或邮箱')
    }
    callback()
  },
}

/** 验证码校验规则 */
export const RULE_CODE: FieldRule = {
  validator: (value, callback) => {
    if (!isVerificationCode(value)) {
      callback('请输入正确的验证码')
    }
    callback()
  },
}
