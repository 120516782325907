<script lang="ts" setup>
  import { requestGetAgentBrand } from '@/api'
  import { useRootStore } from '@/store'
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import { storeToRefs } from 'pinia'
  import { computed, onMounted, reactive } from 'vue'

  const store = useRootStore()
  const { versionLevel: level, expireStatus } = storeToRefs(store)

  const agentBrandData = reactive({
    applyStatus: 0,
    applyTips: '',
    isAgentBrand: 0,
  })
  const getAgentBrand = async () => {
    const { data, success } = await requestGetAgentBrand()
    if (success) {
      agentBrandData.applyStatus = data.apply_status
      agentBrandData.applyTips = data.apply_tips
      agentBrandData.isAgentBrand = data.is_agent_brand
    }
  }
  onMounted(() => getAgentBrand())

  const showText = computed(() => {
    if (agentBrandData.isAgentBrand === 1) {
      if (agentBrandData.applyStatus === 0) {
        return !!agentBrandData.applyTips
      }
    } else if (level.value === 1) {
      if (expireStatus.value === 1) {
        return true
      }
      if (expireStatus.value === 2) {
        return true
      }
    }
    return false
  })

  const goLink = () => {
    window.open(`${import.meta.env.VITE_DOMAIN_URL}/saasPay`, '_blank')
  }
</script>

<template>
  <div v-if="showText" class="toptip-wrapper">
    <svg-icon icon-name="icon-tongzhi" class="error-icon"></svg-icon>
    <div class="top-tip" v-if="agentBrandData.isAgentBrand === 1">
      <span v-if="agentBrandData.applyStatus === 0">{{ agentBrandData.applyTips }}</span>
    </div>
    <div class="top-tip" v-else>
      <span class="tip" v-if="level !== 0" @click="goLink">
        <template v-if="expireStatus === 1"> 即将到期，请及时续费 </template>
        <template v-else-if="expireStatus === 2"> 套餐到期，点击进行续费 </template>
      </span>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .toptip-wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .error-icon {
      margin-right: 3px;
      color: #f52f3e;
    }
  }

  .top-tip {
    display: flex;
    align-items: center;
    color: #f52f3e;

    .time-tip {
      font-size: 12px;
    }

    .tip {
      color: #f52f3e;
      font-size: 12px;
      cursor: pointer;
    }

    :deep(.count-down) {
      span {
        color: #f52f3e;
      }
    }
  }
</style>
