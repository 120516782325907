import { Modal } from '@arco-design/web-vue'
// import BaseBtn from '@components/btn/BaseBtn.vue'
// import BasePopup from '@components/popup/BasePopup.vue'
// import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import { ViteSSGContext } from 'vite-ssg'

export const setupArco = ({ app }: ViteSSGContext) => {
  Modal._context = app._context
  // app.use(ArcoVueIcon)

  /** 常用的基础组件全局注册 */
  // app.component('BaseBtn', BaseBtn)
  // app.component('BasePopup', BasePopup)

  /** iconfont图标组件全局注册 */
  /* app.component(
    // 组件命名不要使用icon前缀，避免与arco动态加载规则混淆
    'BaseIcon',
    Icon.addFromIconFontCn({
      src: new URL('../asset/iconfont/iconfont.js', import.meta.url).href,
    })
  ) */
}
