<script lang="ts" setup>
  import { ColorType } from '@/define'
  import { useRootStore } from '@/store'
  import { linkToContent } from '@/utils/common'
  import { Message } from '@arco-design/web-vue'
  import { openEasyPopup } from '@components/popup/helpers'
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import { useClipboard, useDateFormat } from '@vueuse/core'
  import { computed } from 'vue'
  import { openPreferencePopup } from '../helpers'

  type MenuItem = {
    icon: string
    title: string
    path: string
  }

  const props = withDefaults(defineProps<{ show: boolean }>(), { show: false })
  const emits = defineEmits<{ (e: 'update:show', bool: boolean): void }>()

  const store = useRootStore()
  const userInfo = computed(() => store.userInfo)

  const visible = computed({
    get() {
      return props.show
    },
    set(val: boolean) {
      emits('update:show', val)
    },
  })
  const isMainAccount = computed(() => {
    const pid = userInfo.value?.pid
    return pid === 0
  })
  const versionsStatusForObj = computed(() => {
    const obj = {
      text: '免费版',
      class: 'try',
    }
    switch (userInfo.value.versionLevel) {
      case 1:
        obj.text = '标准版'
        obj.class = 'standard'
        break
      case 2:
        obj.text = '高级版'
        obj.class = 'advanced'
        break
      case 4:
        obj.text = '豪华版'
        obj.class = 'luxury'
        break
    }
    return obj
  })
  const link: MenuItem[][] = [
    [
      {
        icon: 'icon-renshu',
        title: '账号设置',
        path: '/account/settings',
      },
      {
        icon: 'icon-tuandui',
        title: '团队管理',
        path: '/account/rule',
      },
    ],
    [
      {
        icon: 'icon-caiwu',
        title: '费用明细',
        path: '/finance/payments',
      },
      {
        icon: 'icon-dingdan',
        title: '订单管理',
        path: '/finance/orders',
      },
      {
        icon: 'icon-fapiao',
        title: '发票管理',
        path: '/finance/invoices',
      },
    ],
    [
      {
        icon: 'icon-tuichudenglu',
        title: '退出登录',
        path: 'loginOut',
      },
    ],
  ]
  const endDate = computed(() => useDateFormat(userInfo.value.groupEndDate, 'YYYY-MM-DD'))

  const linkEvent = (data: MenuItem) => {
    if (data.path === 'loginOut') {
      handleLogout()
    } else {
      linkToContent(data.path)
    }
  }
  const handleLogout = () => {
    const { close } = openEasyPopup({
      content: '您确定要退出吗',
      btns: [
        {
          text: '取消',
          autoClose: true,
        },
        {
          text: '确定',
          color: ColorType.PRIMARY,
        },
      ],
      onBtnClick: async (text) => {
        if (text === '确定') {
          store.clearToken()
          close()
        }
      },
    })
  }

  const { copy } = useClipboard()
  const copyId = () => {
    copy(`${userInfo.value.uuid}`)
    Message.success('ID复制成功')
  }
</script>

<template>
  <div class="my-info-popup" v-show="visible" @mouseover="visible = true" @mouseleave="visible = false">
    <div class="box">
      <div class="info-module">
        <div class="head-info">
          <div class="head">
            <img :src="userInfo.avatar && userInfo.thumb" alt="" />
          </div>
          <div class="info">
            <div class="info-row">
              <span class="nickname">{{ userInfo.nicknameNew }}</span>
              <span v-if="isMainAccount" class="tool-btn" @click="openPreferencePopup">偏好设置</span>
            </div>
            <div class="user-id info-row">
              <div class="mobile">ID: {{ userInfo.uuid }}</div>
              <svg-icon icon-name="icon-copy" icon-size="18" class="copy" @click="copyId"></svg-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="version" :class="{ [versionsStatusForObj.class]: true }">
        <div class="text">{{ versionsStatusForObj.text }}</div>
        <div class="version-date">
          <span class="date" v-if="userInfo.versionLevel === 0">长期有效</span>
          <template v-else>
            <span class="str" v-if="userInfo.expireStatus === 2">已到期</span>
            <template v-else>
              <span class="str">有效期</span>
              <span class="date">{{ endDate.value }}</span>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="link-module" v-for="(target, index) in link" :key="index">
      <div class="link-item" v-for="item in target" :key="item.path" @click.stop="linkEvent(item)">
        <svg-icon :icon-name="item.icon" icon-size="14" class="icon" />
        <span class="str">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .my-info-popup {
    position: fixed;
    top: 50px;
    right: 20px;
    z-index: 999;
    width: 260px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 8px 20px 0 rgb(0 35 114 / 10%);

    > .box {
      padding: 20px 20px 0;
    }

    .info-module {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      overflow: hidden;

      .head-info {
        display: flex;
        flex: 1;
        overflow: hidden;

        .head {
          flex-shrink: 0;
          width: 56px;
          height: 56px;
          margin-right: 12px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          overflow: hidden;

          .user-id {
            display: flex;
            align-items: center;

            .copy {
              margin-left: 8px;
              color: #939699;
              font-size: 14px;
              cursor: pointer;

              &:hover {
                color: @primary-color;
              }
            }
          }

          .mobile {
            width: 100%;
            overflow: hidden;
            color: #939699;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .nickname {
            overflow: hidden;
            color: #313233;
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .info-row {
      display: flex;
      align-items: center;

      .tool-btn {
        flex-shrink: 0;
        margin-left: 8px;
        color: @primary-color;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .version {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 6px;
      color: #7e9ed8;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 4px 4px 0 0;

      .version-date {
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 12px;
      }

      &.try {
        background-image: url('@images/login/try-bg.png');

        .text {
          color: #7e9ed8;
        }
      }

      &.standard {
        background-image: url('@images/login/standard-bg.png');

        .text {
          color: #2072e1;
        }
      }

      &.advanced {
        background-image: url('@images/login/advanced-bg.png');

        .text {
          color: #b36639;
        }
      }

      &.luxury {
        background-image: url('@images/login/luxury-bg.png');

        .text {
          color: #c88d14;
        }
      }

      .text {
        font-weight: bold;
        font-size: 16px;
        white-space: nowrap;
      }

      .time {
        color: #fff;
        font-size: 12px;
      }
    }

    .link-module {
      padding: 10px 0;
      border-bottom: 1px solid #dcdfe6;

      .link-item {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        color: #626366;
        line-height: 20px;
        cursor: pointer;

        .str {
          font-size: 14px;
        }

        .icon {
          margin-right: 4px;
          color: #939699;
        }

        &:hover {
          color: @primary-color;

          .icon {
            color: @primary-color;
          }
        }
      }
    }
  }
</style>
