import { requestCreateHd, requestCreateScreen } from '@/api'

/** 创建互动作品 */
export const createHd = async (template_id: number, game_type: number) => {
  const { success, data } = await requestCreateHd(template_id, game_type)
  if (success) {
    const info = data.info
    const roundId = info.round_id
    const version = info.template_verison
    const gameId = info.game_id
    const gameType = info.game_type

    const url = `/gameEditor?gameType=${gameType}&gameId=${gameId}&roundId=${roundId}&version=${version}`
    window.open(origin + url, '_blank')
  }
}

/** 创建大屏作品 */
export const createScreen = async (template_id: number) => {
  const { success, data } = await requestCreateScreen(template_id)
  if (success) {
    // TODO: 大屏2.0上线后需要根据版本跳转到新编辑器，目前仅有1.0编辑器
    const url = `${import.meta.env.VITE_DOMAIN_URL}/storeGameEditor/step1?id=${data.id}`
    window.open(url, '_blank')
  }
}

/** 跳转内容引擎 */
export const linkToContent = (router: string, blank = false) => {
  const href = `${import.meta.env.VITE_DOMAIN_URL}${router}`
  if (blank) {
    window.open(href, '_blank')
  } else {
    window.location.href = href
  }
}
