<script lang="ts" setup>
  import { BtnColorType, BtnType, ColorType } from '@/define'
  import { computed, toRefs } from 'vue'
  import { RouteLocationRaw, RouterLink } from 'vue-router'

  const props = withDefaults(
    defineProps<{
      type?: BtnType
      color?: BtnColorType | ColorType
      to?: RouteLocationRaw
      autoSubmit?: boolean
      fontSize?: number
    }>(),
    {
      type: BtnType.DEFAULT,
      color: BtnColorType.DEFAULT,
    }
  )
  const { type, color } = toRefs(props)

  const shape = computed(() => (type.value === BtnType.ICON ? 'circle' : 'square'))
  const colorClass = computed(
    () =>
      `base-btn_${
        type.value === BtnType.PLAIN
          ? 'plain_'
          : type.value === BtnType.TEXT
          ? 'text_'
          : type.value === BtnType.SHOW
          ? 'show_'
          : ''
      }${color.value}`
  )
  const fontSize = computed(() => (props.fontSize ? { fontSize: `${props.fontSize}px` } : {}))
</script>
<template>
  <template v-if="to">
    <router-link :to="to">
      <a-button
        v-bind="$attrs"
        :class="colorClass"
        :shape="shape"
        :html-type="autoSubmit ? 'submit' : undefined"
        :style="fontSize"
        status="normal"
      >
        <template v-if="$slots.icon" #icon>
          <slot name="icon" />
        </template>
        <slot />
      </a-button>
    </router-link>
  </template>
  <template v-else>
    <a-button
      :class="colorClass"
      :shape="shape"
      :html-type="autoSubmit ? 'submit' : undefined"
      status="normal"
      :style="fontSize"
    >
      <template v-if="$slots.icon" #icon>
        <slot name="icon" />
      </template>
      <slot />
    </a-button>
  </template>
</template>
<style lang="less" scoped>
  @import '@styles/variables.less';

  a {
    text-decoration: none;
  }

  .base-btn {
    &_default {
      &[type='button'],
      &[type='submit'],
      &[type='reset'] {
        color: #313233;
        background-color: #fff;
        border-color: @gray-2;

        &:not(.arco-btn-disabled) {
          &:hover {
            color: #2b84e1;
            background-color: #fff;
            border-color: @gray-2;
          }

          &:active {
            color: #2269cc;
            background-color: #fff;
            border-color: #2269cc;
          }
        }

        &.arco-btn-disabled {
          color: #d2d5db;
          background-color: #fff;
          border-color: #d2d5db;
        }
      }
    }

    &_plain_default {
      &[type='button'],
      &[type='submit'],
      &[type='reset'] {
        color: #313233;
        background-color: #fff;
        border-color: @gray-2;

        &:not(.arco-btn-disabled) {
          &:hover {
            color: #313233;
            background-color: #f8fafd;
            border-color: transparent;
          }

          &:active {
            color: #313233;
            background-color: #ebeef5;
            border-color: transparent;
          }
        }

        &.arco-btn-disabled {
          color: #d2d5db;
          background-color: #fff;
          border-color: #d2d5db;
        }
      }
    }

    &_text_default {
      &[type='button'],
      &[type='submit'],
      &[type='reset'] {
        color: #313233;
        border-color: transparent;

        &:not(.arco-btn-disabled) {
          &:hover {
            color: #313233;
            background-color: #f8fafd;
          }

          &:active {
            color: #313233;
            background-color: #ebeef5;
          }
        }

        &.arco-btn-disabled {
          color: #ebeef5;
          border-color: transparent;
        }
      }
    }

    each(@Colors, {
      &_@{key} {
        &[type='button'], &[type='submit'], &[type='reset'] {
          color: #fff;
          background-color: @value;
          &:not(.arco-btn-disabled) {
            &:hover {
              background-color: mix(@value, #000000, 90%);
            }
            &:active {
              background-color: mix(@value, #000000, 80%);
            }
          }
          &.arco-btn-disabled {
            background-color: mix(@value, #ffffff, 40%);
          }
        }
      }

      &_plain_@{key} {
        &[type='button'], &[type='submit'], &[type='reset'] {
          background-color: #fff;
          color: @value;
          border-color: @value;
          &:not(.arco-btn-disabled) {
            &:hover {
              color: mix(@value, #000000, 90%);
              border-color: mix(@value, #000000, 90%);
            }
            &:active {
              color: mix(@value, #000000, 80%);
              border-color: mix(@value, #000000, 80%);
            }
          }
          &.arco-btn-disabled {
            color: mix(@value, #ffffff, 40%);
            border-color: mix(@value, #ffffff, 40%);
          }
        }
      }

      &_text_@{key} {
        &[type='button'], &[type='submit'], &[type='reset'] {
          background-color: transparent;
          color: @value;
          &:not(.arco-btn-disabled) {
            &:hover {
              background-color: mix(@value, #ffffff, 10%);
            }
            &:active {
              background-color: mix(@value, #ffffff, 20%);
            }
          }
          &.arco-btn-disabled {
            color: mix(@value, #ffffff, 40%);
          }
        }
      }

      &_show_@{key} {
        &[type='button'], &[type='submit'], &[type='reset'] {
          color: #fff;
          background-color: @value;
          padding: 13px 41px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC, sans-serif;
          font-weight: 500;
          line-height: 25px;
          height: auto;
        }
      }

      &_show_primary {
        &[type='button'], &[type='submit'], &[type='reset'] {
          background-color: @primary-color;
          color: #fff;
          padding: 13px 41px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC, sans-serif;
          font-weight: 500;
          line-height: 25px;
          height: auto;
        }
      }

      &_show_white {
        &[type='button'], &[type='submit'], &[type='reset'] {
          background-color: #fff;
          color: @primary-color;
          padding: 13px 41px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          line-height: 25px;
          height: auto;
        }
      }
    });
  }
</style>
