import '@arco-design/web-vue/dist/arco.css'
import '@styles/font.css'
import '@styles/index.less'
import { ViteSSG, ViteSSGContext } from 'vite-ssg'
import App from './App.vue'
import { setupBoot } from './boot'
import routes from './routes'

export const createApp = ViteSSG(
  // the root component
  App,
  // vue-router options
  {
    routes,
    scrollBehavior() {
      return { top: 0 }
    },
  },
  // function to have custom setups
  (context: ViteSSGContext) => {
    setupBoot(context)
  }
)
