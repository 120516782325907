// import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import { assign, invoke, isString } from 'lodash-es'
import { Component, createApp, createVNode, h, mergeProps, reactive, Suspense } from 'vue'
import BasePopup from './BasePopup.vue'
import FormPopup from './FormPopup.vue'
import LoginPopup from './login/LoginPopup.vue'
import PrivacyAgreement from './login/PrivacyAgreement.vue'
import UserArgeement from './login/UserAgreement.vue'

/** 基础弹窗的参数 */
export type BasePopupProps = InstanceType<typeof BasePopup>['$props']

/** 表单弹窗的参数 */
export type FormPopupProps = InstanceType<typeof FormPopup>['$props']

type CommonProps = BasePopupProps | FormPopupProps

/**
 * 开启简单弹窗页面，没有底部按钮
 *
 * @param config 只传字符串将只显示图标跟文字，设置配置则调整标题及提示
 */
export const openEasyPopup = (config: string | BasePopupProps) =>
  openCustomPopup(BasePopup, assign({ simple: true, title: '提示' }, isString(config) ? { content: config } : config))

/**
 * 打开自定义弹窗
 * @param component 组件
 * @param props 参数
 * @returns 返回promise，then为弹窗关闭
 */
export const openCustomPopup = <P = Record<string, unknown> & Partial<CommonProps>>(
  component: Component,
  props?: P
) => {
  const propsAssemble = reactive({ visible: true })
  if (props) {
    assign(propsAssemble, props)
  }

  const root = document.createElement('div')
  const parent = document.getElementById('app') ?? document.body
  parent.appendChild(root)
  const app = createApp({
    setup: () => {
      return () =>
        h(
          Suspense,
          createVNode(
            component,
            mergeProps(propsAssemble, {
              'onUpdate:visible': (bool: boolean) => {
                propsAssemble.visible = bool
                /* 关闭时触发关闭回调 */
                if (!bool && props) {
                  invoke(props, 'onHide')
                }
              },
              onClose: () => {
                app.unmount()
                parent.removeChild(root)
              },
              onHide: () => {
                propsAssemble.visible = false
              },
            })
          )
        )
    },
  })

  // app.use(ArcoVueIcon)
  app.mount(root)

  const close = () => {
    propsAssemble.visible = false
  }

  return {
    close,
  }
}

/**
 * 触发登录
 */
export const openLogin = <P = InstanceType<typeof LoginPopup>['$props']>(props?: P) =>
  openCustomPopup(LoginPopup, props)

/**
 * 打开用户协议
 */
export const openUserAgreement = () => openCustomPopup(UserArgeement)

/**
 * 打开隐私协议
 */
export const openPrivacy = () => openCustomPopup(PrivacyAgreement)
