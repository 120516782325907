import { DataWrapper } from '@/define'
import { get, post } from '../helpers'

/**
 * 获取模板预览信息
 * @returns
 */
export const getTemplateInfo = (template_id: number, game_type: number) =>
  get<DataWrapper<Api.templateInfo>>({
    url: '/gamesdk.php/hudongonlinegame/getRoundForTry',
    params: {
      template_id,
      game_type,
    },
  })

/**
 * 获取模板预览地址
 * @returns
 */
export const getTemplatePreview = (template_id: number, game_type: number) =>
  get<DataWrapper<Api.TemplateData>>({
    url: '/gamesdk.php/Hudongonlinegamemobile/encodeTemplateData',
    params: {
      template_id,
      game_type,
    },
  })

/**
 * 创建互动作品
 * @param {Number} template_id 模板id
 * @param {Number} game_type 模板类型
 * @returns
 */
export const requestCreateHd = (template_id: number, game_type: number) =>
  post<DataWrapper<Api.TemplateInfo>>({
    url: '/brand.php/hd.works/addByTemplate',
    params: {
      game_id: template_id,
      game_type,
    },
  })

/**
 * 创建大屏作品
 * @returns
 */
export const requestCreateScreen = (tempalte_id: number) =>
  post<DataWrapper<Api.ScreenTemplateInfo>>({
    url: '/brand.php/Hudongtemplate/useTemplate',
    params: {
      id: tempalte_id,
    },
  })
