import { openCustomPopup } from '@components/popup/helpers'
import { ref } from 'vue'
import PreferencesPopup from './components/PreferencesPopup.vue'

export const showMealInfoPopup = ref(false)
export const showUserInfoPopup = ref(false)

export const hideAllPopup = () => {
  showMealInfoPopup.value = false
  showUserInfoPopup.value = false
}

export const openPreferencePopup = <P = InstanceType<typeof PreferencesPopup>['$props']>(props?: P) =>
  openCustomPopup(PreferencesPopup, props)
