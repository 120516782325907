<script lang="ts" setup>
  import { requestGetBrangTagsList, requestGetUserHobbies, requestSaveHobby } from '@/api'
  import { ColorType } from '@/define'
  import { linkToContent } from '@/utils/common'
  import { Message } from '@arco-design/web-vue'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import BasePopup from '@components/popup/BasePopup.vue'
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import { cloneDeep } from 'lodash-es'
  import { ref } from 'vue'

  type HobbiesItem = {
    id: number
    name: string
  }

  type BrangTagItem = {
    id: number
    name: string
    isRadio?: boolean
    hasChild?: boolean
    showChild?: boolean
    prevId?: number | string
    childTagWrapId?: string
    choose?: boolean
    includeChildTag?: boolean
    child: BrangTagItem[]
  }

  const emits = defineEmits<{
    (e: 'hide'): void
  }>()

  const allTagList = ref<BrangTagItem[]>([])
  const selectedTags = ref<BrangTagItem[]>([])
  const userHobbies = ref<HobbiesItem[]>([])
  // 单选的标签集合
  const ratioIds: Record<number, number> = {
    149: 1,
  }

  const resetData = () => {
    selectedTags.value = []
  }
  const getUserHobbies = async () => {
    const { data, success } = await requestGetUserHobbies()
    if (success) {
      return data.list || []
    }
    return []
  }
  const getBrangTagsList = async () => {
    const { data, success } = await requestGetBrangTagsList()
    if (success) {
      return data.list || []
    }
    return []
  }

  const init = async () => {
    resetData()
    // 获取用户当前的偏好标签
    userHobbies.value = await getUserHobbies()

    const res: BrangTagItem[] = await getBrangTagsList()

    res.forEach((tags) => {
      if (tags.child && tags.child.length) {
        let hasChildFlag = false
        tags.isRadio = !!ratioIds[tags.id]
        tags.child.forEach((tag) => {
          tag.hasChild = !!(tag.child && tag.child.length)
          if (tag.hasChild) {
            tags.isRadio = !!ratioIds[tags.id]
            tag.showChild = false
            tags.prevId = ''
            tag.childTagWrapId = `childTagWrap${tag.id}`
            tag.child.forEach((childTag) => {
              const choosed = userHobbies.value.some((hobby) => hobby.id === childTag.id)
              childTag.choose = choosed
              if (choosed) {
                selectedTags.value.push(childTag)
              }
            })
            hasChildFlag = true
          } else {
            const choosed = userHobbies.value.some((hobby) => hobby.id === tag.id)
            tag.choose = choosed
            if (choosed) {
              selectedTags.value.push(tag)
            }
          }
        })
        if (hasChildFlag) {
          tags.includeChildTag = true
        }
      }
    })

    allTagList.value = cloneDeep(res)
  }
  /**
   * 选中单个标签，对标签进行选中打标
   * @param {Object} tag 当前点击的标签信息
   */
  const handleChooseTag = (tag: BrangTagItem, parent: BrangTagItem) => {
    if (parent.isRadio) {
      parent.child.forEach((item) => {
        if (item.id === tag.id) {
          item.choose = !item.choose
        } else {
          item.choose = false
        }
        if (item.choose) {
          selectedTags.value.push(tag)
        } else {
          const findIdx = selectedTags.value.findIndex((selectItem) => selectItem.id === item.id)
          if (findIdx > -1) {
            selectedTags.value.splice(findIdx, 1)
          }
        }
      })
    } else {
      tag.choose = !tag.choose
      if (!tag.choose) {
        const findIdx = selectedTags.value.findIndex((item) => item.id === tag.id)
        if (findIdx > -1) {
          selectedTags.value.splice(findIdx, 1)
        }
      } else {
        selectedTags.value.push(tag)
      }
    }
  }
  const handleShowChild = (parentTags: BrangTagItem, tagInfo: BrangTagItem) => {
    const contentWrapDom = document.querySelector('.tag-type-content-wrap') as HTMLElement
    const prevId = parentTags.prevId
    const currentId = tagInfo.childTagWrapId
    const tagWrapDom: HTMLElement = document.querySelector(`#tag-wrap${currentId}`) as HTMLElement
    const leftNum = tagWrapDom && contentWrapDom ? contentWrapDom.offsetLeft - tagWrapDom.offsetLeft : 0
    if (prevId) {
      const prevDom: HTMLElement = document.querySelector(`#${prevId}`) as HTMLElement
      if (prevDom) {
        // console.log('prevDom :>> ', prevDom)
        prevDom.style.top = '-9999px'
      }
      if (currentId === prevId) {
        parentTags.prevId = ''
        for (let i = 0; i < parentTags.child.length; i++) {
          const info = parentTags.child[i]
          const eleDom: HTMLElement = document.querySelector(`#tag-wrap${info.childTagWrapId}`) as HTMLElement
          if (eleDom && eleDom.offsetTop === tagWrapDom.offsetTop) {
            eleDom.style.marginBottom = '0px'
          }
        }
        return
      }
    }

    const currentDom: HTMLElement = document.querySelector(`#${currentId}`) as HTMLElement
    if (currentDom) {
      currentDom.style.top = '36px'
      currentDom.style.left = `${leftNum}px`
      for (let i = 0; i < parentTags.child.length; i++) {
        const info = parentTags.child[i]
        const eleDom: HTMLElement = document.querySelector(`#tag-wrap${info.childTagWrapId}`) as HTMLElement
        if (eleDom && eleDom.offsetTop === tagWrapDom.offsetTop) {
          eleDom.style.marginBottom = `${currentDom.offsetHeight}px`
        } else if (eleDom) {
          eleDom.style.marginBottom = '0px'
        }
      }
    }
    parentTags.prevId = currentId
  }
  // 处理所选中的信息
  const handleSubmit = async () => {
    if (!selectedTags.value.length) {
      Message.warning('请选择用户偏好')
      return
    }
    // 判断当前选中的标签与用户初始选中的标签一致
    if (userHobbies.value.length === selectedTags.value.length) {
      const noChange = userHobbies.value.every((ele) => {
        const findItem = selectedTags.value.find((item) => item.id === ele.id)
        return !!findItem
      })
      if (noChange) {
        emits('hide')
        return
      }
    }
    // 更新用户偏好
    const sysTags = selectedTags.value.map((tag) => tag.id).join(',')
    const { success } = await requestSaveHobby(sysTags)
    if (success) {
      emits('hide')
      linkToContent('/home/index')
    }
  }

  init()
</script>

<template>
  <base-popup class="preferences-dialog" width="960px" title="选择你的偏好内容让推荐更精准">
    <div class="content">
      <div class="confirm">可选择多选您偏好的标签</div>
      <div
        class="module-wrap"
        v-for="(tags, index) in allTagList"
        :key="`perference_${tags.id}_${index}`"
        :class="{ 'has-child-type': tags.includeChildTag }"
      >
        <div class="tag-type-name">{{ tags.name }}：</div>
        <div class="tag-type-content-wrap">
          <div
            class="tag-wrap"
            v-for="(tag, tagIdx) in tags.child"
            :key="`perference_tag_${tag.id}`"
            :id="`tag-wrap${tag.childTagWrapId}`"
            :class="{ 'has-child': tag.hasChild }"
          >
            <div class="tag-item">
              <template v-if="tag.hasChild">
                <!-- detail-child -->
                <div
                  class="tag-name"
                  @click="handleShowChild(tags, tag)"
                  :class="{ 'detail-child': tags.prevId === tag.childTagWrapId, 'has-child': tag.hasChild }"
                >
                  <span>{{ tag.name }}</span>
                  <svg-icon v-show="tags.prevId !== tag.childTagWrapId" icon-name="icon-xiamian" class="arrow" />
                  <svg-icon v-show="tags.prevId === tag.childTagWrapId" icon-name="icon-shangmian" class="arrow" />
                </div>
              </template>
              <template v-else>
                <div class="tag-name no-child" :class="{ choosed: tag.choose }" @click="handleChooseTag(tag, tags)">
                  <span>{{ tag.name }}</span>
                  <svg-icon class="checked-icon" icon-name="icon-xuanzhong" />
                </div>
              </template>
            </div>
            <div class="child-wrap" v-if="tag.hasChild" :id="tag.childTagWrapId">
              <div
                class="child-tag-wrap"
                v-for="(childTag, childTagIdx) in tag.child"
                :key="`childTag-${tag.name}-${tagIdx}-${childTag.id}-${childTagIdx}`"
              >
                <div class="child-tag" :class="{ choosed: childTag.choose }" @click="handleChooseTag(childTag, tag)">
                  <span>{{ childTag.name }}</span>
                  <svg-icon class="checked-icon" icon-name="icon-xuanzhong" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="text">
        已选择
        <span class="special-text">{{ selectedTags.length }}</span>
        个标签
      </span>
      <base-btn class="submit" :color="ColorType.PRIMARY" @click="handleSubmit">我选好了，进入平台</base-btn>
    </div>
  </base-popup>
</template>

<style lang="less" scoped>
  .preferences-dialog {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 56px;
      padding: 0 20px;
      background: #f6f9fd;

      .title {
        color: #313233;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }

      .close {
        color: #939699;
        font-size: 20px;
      }
    }

    .content {
      // width: 960px;
      height: 480px;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto;

      .confirm {
        margin-bottom: 30px;
        color: #939699;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      padding: 0 20px;
      background-color: #fff;
      border-top: 1px solid #dcdfe6;

      .text {
        color: #313233;
        font-size: 14px;
        line-height: 36px;
        vertical-align: middle;

        .special-text {
          color: #2b84ff;
          font-weight: 500;
          font-size: 24px;
          vertical-align: sub;
        }
      }

      .submit {
        font-size: 14px;
        line-height: 20px;
        background-color: #2b84ff;
        border-color: #2b84ff;
      }
    }

    .module-wrap {
      display: flex;
      margin-bottom: 20px;

      .tag-type-name {
        width: 120px;
        color: #313233;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
      }

      &.has-child-type {
        .tag-type-name {
          line-height: 36px;
        }

        .tag-type-content-wrap {
          padding-left: 0;
        }
      }

      .tag-type-content-wrap {
        width: 818px;
        padding-left: 4px;

        .tag-wrap {
          position: relative;
          display: inline-block;
          margin-right: 20px;
          text-align: center;
          background-color: #fff;
          cursor: pointer;

          .tag-item {
            .tag-name {
              display: flex;
              align-items: center;
              padding: 4px;
              color: #313233;
              line-height: 20px;

              .checked-icon {
                margin-left: 4px;
                opacity: 0;
              }

              &.choosed {
                color: #fff;
                background-color: #2b84ff;
                border-radius: 2px;

                .checked-icon {
                  opacity: 1;
                }
              }

              &.detail-child {
                color: #2b84ff;
                background-color: #f6f9fd;
              }

              &.has-child {
                padding: 8px;
              }

              &.no-child {
                margin-bottom: 4px;
              }

              .arrow {
                margin-left: 6px;
                font-size: 12px;
              }
            }
          }

          .child-wrap {
            position: absolute;
            top: -9999px;
            left: 0;
            z-index: 10;
            display: flex;
            flex-wrap: wrap;
            width: 818px;
            padding: 0 4px;
            background-color: #f6f9fd;

            .child-tag-wrap {
              margin: 4px 0;
            }

            .child-tag {
              // width: 56px;
              display: flex;
              align-items: center;
              height: 28px;
              margin-right: 22px;
              padding: 4px;
              color: #626366;
              font-size: 14px;
              background: #f6f9fd;
              border-radius: 2px;

              .checked-icon {
                margin-left: 4px;
                opacity: 0;
              }

              &.choosed {
                color: #fff;
                background-color: #2b84ff;

                .checked-icon {
                  opacity: 1;
                }
              }
            }
          }

          &.has-child {
            margin-right: 12px;
          }
        }

        .more-tags-wrap {
          display: inline-block;
          width: 100px;
          margin-right: 12px;
          text-align: center;
          background-color: #eee;
          cursor: pointer;

          &.actived {
            background-color: #f00;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
