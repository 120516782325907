<script lang="ts" setup>
  import { eventReport, useQidianService } from '@/composable'
  import { OpenServiceType } from '@/define'
  import { useConcat } from '@components/popup/concat/helper'
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import { useWindowScroll } from '@vueuse/core'
  import { onMounted, ref, watch } from 'vue'

  const showSticky = ref(true)
  const isToTopShow = ref(false)
  const { openConcat } = useConcat()

  const { y } = useWindowScroll()
  watch(
    () => y.value,
    (val: number) => {
      if (val >= window.innerHeight) {
        isToTopShow.value = true
      } else {
        isToTopShow.value = false
      }
    }
  )

  const openService = () => {
    eventReport({
      event_id: 'pc_customer_service_click',
      parm: OpenServiceType.SLIDER,
    })
    onServiceClick()
  }

  const openVideo = () => {
    eventReport({
      event_id: 'pc_school_marketing_click',
    })
    const path = `${import.meta.env.VITE_DOMAIN_URL}/home/video`
    window.open(path, '_blank')
  }
  const toTop = () => {
    window.scrollTo(0, 0)
  }

  const { onServiceClick } = useQidianService()

  const onConcatPopClick = () => {
    eventReport({
      event_id: 'pc_telephone_btn_click',
    })
    openConcat(0)
  }

  onMounted(() => {
    /** 组件挂载后，触发定时提示客服 */
    const config = localStorage.getItem('concatLocalConfig')
    const concatLocalConfig = config ? JSON.parse(config) : {}
    if (concatLocalConfig.remember) {
      return
    }
    let timeout = 60
    if (!concatLocalConfig.isAccessed) {
      timeout = 30
    }
    concatLocalConfig.isAccessed = true
    localStorage.setItem('concatLocalConfig', JSON.stringify(concatLocalConfig))
    openConcat(timeout)
  })
</script>

<template>
  <div class="custom-affix" v-show="showSticky" :style="{ bottom: isToTopShow ? '50px' : '117px' }">
    <div class="list-box">
      <div class="list-box-view">
        <a id="dxhd_customer_service" style="display: none"></a>
        <a class="item mark-dash" @click="openService">
          <svg-icon icon-name="icon-kefu" icon-size="24" class="custum-icon"></svg-icon>
          <div>在线客服</div>
        </a>
        <a class="item hight-light" @click="onConcatPopClick">
          <svg-icon icon-name="icon-dianhua" icon-size="24" class="custum-icon"></svg-icon>
          <div>电话咨询</div>
        </a>
        <a class="item" @click="openVideo">
          <svg-icon icon-name="icon-xueyuan" icon-size="24" class="custum-icon"></svg-icon>
          <div>营销学院</div>
        </a>
        <a class="item" v-show="isToTopShow" @click="toTop">
          <svg-icon icon-name="icon-xiamian" icon-size="24" class="custum-icon rotate"></svg-icon>
          <div>置顶</div>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .custom-affix {
    position: fixed;
    right: 17px;
    // bottom: 107px;
    z-index: 990;
    text-align: right;

    .list-box {
      display: inline-block;
      width: 64px;

      .list-box-view {
        background: #fff;
        box-shadow: 0 2px 8px rgb(22 52 128 / 10%);

        .item {
          position: relative;
          display: block;
          padding: 10px 8px 12px;
          color: #313233;
          font-size: 12px;
          text-align: center;
          cursor: pointer;

          &.hight-light {
            color: #fff;
            background-color: #f52f3e;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #dcdfe6;
          }

          &.mark-dash::after {
            position: absolute;
            top: 9px;
            right: 6px;
            width: 6px;
            height: 6px;
            background-color: #f52f3e;
            border-radius: 50%;
            content: '';
          }

          .custum-icon {
            padding-bottom: 4px;
            font-size: 24px;

            &.rotate {
              padding: 0;
              // padding-top: 4px;
              transform: rotateX(180deg);
            }
          }
        }
      }
    }
  }
</style>
