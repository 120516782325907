import { dataTracking, sendBeacon } from '@/api'
import { useNetwork } from '@vueuse/core'
import UAParser from 'ua-parser-js'
import { dataTrackingJson, evType, pageType, publicReport } from '../define/report'
import { BaseWrapper } from '../define/wrapper'
const ua = UAParser()
const { device, os } = ua

type param = evType & pageType & publicReport

const getDefaultConfig = (): dataTrackingJson => {
  const data = {
    common: {
      device: {
        platf: '',
        dev_id: '',
        imei: '',
        mac: '',
        adid: '',
        mtype: '',
        os: '',
        os_ver: '',
        device_type: '',
        dev_make: '',
      },
      account: {
        wechat_uid: '',
      },
      scene: {
        net: '',
        coordinate: '',
      },
    },
    action: {
      status: undefined,
      stay_time: '',
      progress: '',
    },
    start_source: {
      active_id: '',
      active_from: '',
    },
    identify: {
      page_category: '',
      page: '',
      page_id: '',
      parm: '',
    },
    referer: {
      f_page: '',
      f_page_id: '',
      f_page_location: '',
    },
    event: {
      event_id: '',
      obj_type: '',
      obj_id: '',
      parm: '',
    },
  }
  return data
}

// 当前埋点pv
const currentPV: dataTrackingJson = getDefaultConfig()
const currentTime = 0
// 上一次页面埋点对象
let prePv: dataTrackingJson = getDefaultConfig()
// 上一次埋点开始时间
let preTime = 0
let userEffectType: unknown

const initReport = () => {
  window.onbeforeunload = () => {
    const now = new Date().getTime()
    prePv.action.status = 0
    prePv.action.stay_time = now - currentTime
    sendBeacon(prePv)
  }
  const { effectiveType } = useNetwork()
  userEffectType = effectiveType as unknown
}

if (!import.meta.env.SSR) {
  initReport()
}

/*
 * @returns 获取公共参数
 */
const getPublicParam = (): publicReport => {
  let devicetype = device.type ? (device.type === 'tablet' ? 'ipad' : device.type) : 'pc'
  devicetype = devicetype === 'mobile' ? 'phone' : devicetype
  return {
    device_type: devicetype,
    dev_make: device.vendor,
    mtype: device.model,
    os: os.name,
    os_ver: os.version,
    net: userEffectType || '',
  }
}

const switchServerData = (data: param) => {
  const dataTrackingJson = {
    common: {
      device: {
        platf: data.platf || '',
        dev_id: data.dev_id || '',
        imei: data.imei || '',
        mac: data.mac || '',
        adid: data.adid || '',
        mtype: data.mtype || '',
        os: data.os || '',
        os_ver: data.os_ver || '',
        device_type: data.device_type || '',
        dev_make: data.dev_make || '',
      },
      account: {
        wechat_uid: data.wechat_uid || '',
      },
      scene: {
        net: data.net || '',
        coordinate: data.coordinate || '',
      },
    },
    action: {
      status: data.status || 2,
      stay_time: data.stay_time || '',
      progress: data.progress || '',
    },
    start_source: {
      active_id: data.active_id || '',
      active_from: data.active_from || '',
    },
    identify: {
      page_category: data.page_category || '',
      page: data.page || '',
      page_id: data.page_id || '',
      parm: data.parm || '',
    },
    referer: {
      f_page: data.f_page || '',
      f_page_id: data.f_page_id || '',
      f_page_location: data.f_page_location || '',
    },
    event: {
      event_id: data.event_id || '',
      obj_type: data.obj_type || '',
      obj_id: data.obj_id || '',
      parm: data.parm || '',
      custom_value: data.custom_value,
    },
  }
  return dataTrackingJson
}

/**
 * 事件埋点
 * @param evParam {@link evType}
 * @returns Promise<BaseWrapper>
 */
export const ev = (evParam: evType): Promise<BaseWrapper> => {
  const commonObj = getPublicParam()
  const param = {
    ...commonObj,
    ...evParam,
  }
  const serverData = switchServerData(param)
  return dataTracking(serverData)
}

/**
 * 页面埋点
 * @param page {@link pageType}
 * @param env {@link env}
 * @returns Promise<BaseWrapper>
 */
export const pv = (page: pageType): Promise<BaseWrapper> => {
  // 有上一次埋点记录
  if (Object.keys(prePv).length !== 0) {
    prePv.action.status = 0
    // 计算出上一次埋点停留的事件差
    const now = new Date().getTime()
    const stayTime = now - preTime
    prePv.action.stay_time = stayTime
    dataTracking(prePv)
  }
  const commonObj = getPublicParam()
  const param = {
    ...commonObj,
    ...page,
  }
  const serverData = switchServerData(param)

  prePv = serverData
  // 上一次埋点开始时间
  preTime = new Date().getTime() // eslint-disable-line @typescript-eslint/no-unused-vars

  return dataTracking(serverData)
}

export const closeSendBeacon = () => {
  const now = new Date().getTime()
  prePv.action.status = 0
  prePv.action.stay_time = now - currentTime
  sendBeacon(currentPV)
}
