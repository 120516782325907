// 获取assets静态资源
export const getAssetsFile = (url: string, base = 'images') => {
  let path = `../assets/${base}/${url}`
  const modules = import.meta.glob('/src/assets/**/*.{png,svg,jpg,jpeg,json,webm,mp4}', {
    eager: true,
    import: 'default',
  })

  path = path.replace(/.*?(\/assets\/)/, '/src$1')
  return modules[path] as string
}
