/** 登录类型 */
export enum LoginType {
  PASSWORD = 1,
  SMS,
  RESET,
}

// 发送验证码类型常量
export enum SendCodeType {
  LOGIN = 'login', // 登录
  CHECK_ACCOUNT = 'check', // 校验旧账号
  EDIT_ACCOUNT = 'edit', // 修改账号
  PASSWORD = 'password', // 修改密码
  PROFILE = 'profile', // 修改资料
  REGISTER_SUBACC = 'register_subacc', // 修改子账户手机
  FORGET_PASSWORD = 'forget', // 重置密码
}
