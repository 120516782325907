// 邮箱判断
export const isEmail = (email: string) => {
  const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
  return reg.test(email)
}

// 手机号码判断
export const isMobile = (mobile: string) => {
  const reg = /^1[345789]\d{9}$/
  return reg.test(mobile)
}

// 手机/邮箱验证码校验
export const isVerificationCode = (code: string) => {
  const reg = /^\d{6}$/
  return reg.test(code)
}
