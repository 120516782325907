<script lang="ts" setup>
  import { AlignType, BtnType, ColorType } from '@/define'
  import BaseBtnGroup from '@components/btn/BaseBtnGroup.vue'
  import { getAlignBtns } from '@components/btn/helpers'
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import { useVModel } from '@vueuse/core'
  import { computed, Ref, toRefs } from 'vue'

  const props = withDefaults(
    defineProps<{
      /** 是否可见 */
      visible?: boolean
      /** 简单样式 */
      simple?: boolean
      /** 标题 */
      title?: string
      /**    */
      content?: string
      /** 无slot时文本提示 */
      description?: string
      /** 窗口宽度，默认30rem */
      width?: string
      /** 弹窗填充加载 */
      loading?: boolean
      /** 按钮组 */
      btns?: {
        /** 按钮文本 */
        text: string
        /** 按钮显示加载中 */
        loading?: boolean | Ref<boolean>
        /** 按钮类型 */
        type?: BtnType
        /** 按钮颜色 */
        color?: ColorType
        /** 按钮在弹窗中的位置 */
        align?: AlignType
        /** 按钮禁用 */
        disabled?: boolean
        /** 点击按钮时自动关闭弹窗 */
        autoClose?: boolean
      }[]
    }>(),
    {
      simple: false,
      title: '提示',
      content: '内容',
      width: '480px',
      btns: () => [],
    }
  )
  const emits = defineEmits<{ (e: 'update:visible', bool: boolean): void; (e: 'btnClick', text: string): void }>()
  const visibleRef = useVModel(props, 'visible', emits)

  const { btns } = toRefs(props)
  const leftBtns = computed(() => getAlignBtns(btns.value, AlignType.LEFT))
  const centerBtns = computed(() => getAlignBtns(btns.value, AlignType.CENTER))
  const rightBtns = computed(() => getAlignBtns(btns.value))

  const onBtnClick = (text: string, autoClose?: boolean) => {
    if (autoClose) {
      visibleRef.value = false
    }
    emits('btnClick', text)
  }
</script>
<template>
  <a-modal :simple="true" :mask-closable="false" v-model:visible="visibleRef" :width="width">
    <a-spin class="popup-box" :loading="loading">
      <div class="popup-box-title" :class="{ 'simple-box-title': simple }">
        <span class="title">{{ title }}</span>
        <svg-icon icon-name="icon-close" icon-size="20" style="cursor: pointer" @click="visibleRef = false"></svg-icon>
      </div>
      <slot>
        <div class="popup-box-content">
          <div class="content">
            <!-- <icon-exclamation-circle-fill class="content-icon" /> -->
            <span class="content-text">{{ content }}</span>
          </div>
          <div class="description">{{ description }}</div>
        </div>
      </slot>
      <div v-if="btns.length" class="popup-box-btn" :class="{ 'simple-box-btn': simple }">
        <div class="btn-box">
          <slot name="btns-before" />
          <slot name="btns">
            <base-btn-group class="btn-start" :btns="leftBtns" @btn-click="onBtnClick" />
            <base-btn-group class="btn-center" :btns="centerBtns" @btn-click="onBtnClick" />
            <base-btn-group class="btn-end" :btns="rightBtns" @btn-click="onBtnClick" />
          </slot>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<style lang="less" scoped>
  .popup-box {
    width: 100%;

    .popup-box-title {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding-right: 16px;
      padding-left: 16px;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      background-color: #f8fafd;

      .title {
        color: #283233;
      }

      .title-icon {
        width: 24px;
        height: 24px;
        color: #c4c7cc;
        cursor: pointer;

        &:hover {
          color: #939699;
        }
      }

      .simple-title-icon {
        width: 20px;
        height: 20px;
      }
    }

    .simple-box-title {
      height: 40px;
      padding-right: 16px;
      padding-left: 16px;
      font-size: 14px;
      line-height: 20px;
    }

    .popup-box-content {
      padding: 20px;

      .content {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        &:not([hidden]) ~ :not([hidden]) {
          --tw-space-x-reverse: 0;

          margin-right: calc(12px * var(--tw-space-x-reverse));
          margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
        }

        .content-icon {
          flex: none;
          color: #f86e00;
          font-size: 20px;
          line-height: 28px;
        }

        .content-text {
          overflow: hidden;
          color: #313233;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .description {
        color: #939699;
        text-indent: 32px;
      }
    }

    .popup-box-btn {
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;

      .btn-box {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .btn-start {
          justify-content: flex-start;
        }

        .btn-center {
          justify-content: center;
        }

        .btn-end {
          justify-content: flex-end;
        }
      }
    }

    .simple-box-btn {
      padding: 12px 20px;
      border-top-width: 1px;
    }
  }
</style>
