import { requestGetUserInfo } from '@/api'
import { useStorage } from '@vueuse/core'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'

const LOCAL_TOKEN = 'LOCAL_TOKEN'
const tokenRef = useStorage(LOCAL_TOKEN, '')
const accessTokenRef = useStorage('Access-Token', '')

interface RootState {
  /** 用户id */
  id: number | string
  /** token */
  token: string
  accessToken: string
  /** 昵称 */
  nick: string
  /** 头像 */
  avatar: string
  /** 头像 */
  thumb: string
  /** 手机 */
  mobile: string
  /** 邮箱 */
  email: string
  /** 当前版本 0: 试用版 1：标准版 2：高级版 4：豪华版 */
  versionLevel: 0 | 1 | 2 | 4
  /** 套餐到期情况 0-未到期 1-快到期 2-已到期 */
  expireStatus: 0 | 1 | 2
  nicknameNew: string
  pid: number
  uuid: number
  groupEndDate: string
}

export const useRootStore = defineStore({
  id: 'root',
  state: (): RootState => ({
    id: 0,
    token: '',
    accessToken: '',
    nick: '',
    avatar: '',
    thumb: '',
    mobile: '',
    email: '',
    nicknameNew: '',
    versionLevel: 0,
    expireStatus: 0,
    pid: 0,
    uuid: 0,
    groupEndDate: '',
  }),
  getters: {
    /** 是否登录 */
    isLogin: (state) => !isEmpty(state.token),
    /** 常用的用户数据信息 */
    userInfo: (state) => state,
  },
  actions: {
    async initialize() {
      this.token = tokenRef.value
      this.accessToken = accessTokenRef.value
      if (this.token && !this.id) {
        this.getUserInfo()
      }
    },
    /** 清除登录信息 */
    clearToken() {
      this.id = 0
      this.nick = ''
      this.avatar = ''
      this.mobile = ''
      this.token = ''

      tokenRef.value = null
      accessTokenRef.value = null
    },

    /** 设置token */
    async setToken(data: Api.LoginData) {
      this.token = tokenRef.value = data.brandtoken
      this.accessToken = accessTokenRef.value = data.brandtoken
      this.getUserInfo()
    },

    /** 设置用户信息 */
    async setUserInfo(data: Api.UserInfo) {
      const { userinfo } = data
      this.id = userinfo.id
      this.nick = userinfo.nickname
      this.avatar = userinfo.avatar[0]?.photo
      this.thumb = userinfo.avatar[0]?.thumb
      this.mobile = userinfo.mobile
      this.email = userinfo.email
      this.versionLevel = userinfo.version_level
      this.expireStatus = userinfo.expire_status
      this.nicknameNew = userinfo.nickname_new
      this.groupEndDate = userinfo.group_end_date
      this.pid = userinfo.pid
      this.uuid = userinfo.uuid
    },

    /** 获取用户信息 */
    async getUserInfo() {
      const { success, data } = await requestGetUserInfo()
      if (success) {
        this.setUserInfo(data)
      } else {
        this.clearToken()
      }
    },
  },
})
