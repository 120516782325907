<script lang="ts" setup>
  import { useRootStore } from '@/store'
  import { computed } from 'vue'
  import { showMealInfoPopup, showUserInfoPopup } from '../helpers'
  import UserInfoPopup from './UserInfoPopup.vue'

  const store = useRootStore()
  const userInfo = computed(() => store.userInfo)
  const onMouseOver = () => {
    showMealInfoPopup.value = false
    showUserInfoPopup.value = true
  }
</script>

<template>
  <div>
    <div class="store-avatar-wrapper" @mouseover="onMouseOver">
      <img :src="userInfo.thumb" />
    </div>
    <user-info-popup v-model:show="showUserInfoPopup"></user-info-popup>
  </div>
</template>

<style lang="less" scoped>
  .store-avatar-wrapper {
    width: 32px;
    height: 32px;
    border: 1px solid #dcdfe6;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
</style>
