import { DataWrapper, ListWrapper } from '@/define'
import { get, post } from '../helpers'

/**
 * 登录/注册
 * @returns
 */
export const requestLogin = (params: {
  /**
   * 账号
   */
  account_no: string
  /**
   * 账号类型，0-未知，1-手机号，2-邮箱 （默认0-未知，则手机号和邮箱均可）
   */
  account_type?: number
  /**
   * 分享代理ID
   */
  agent_id?: string
  /**
   * 验证码，仅当type=2时，此值有效且必填
   */
  code?: string
  /**
   * 登录方式，1-密码登录，2-验证码登录
   */
  login_type: number
  /**
   * 密码，仅当type=1时，此值有效且必填
   */
  password?: string
}) =>
  post<DataWrapper<Api.LoginData>>({
    url: '/brand.php/front/accountLogin',
    params,
  })

/**
 * 重置密码
 * @returns
 */
export const requestResetPassword = (params: { account_no: string; code: string; password: string }) =>
  post({
    url: '/brand.php/front/find',
    params,
  })

/**
 * 发送验证码
 * @returns
 */
export const requestSendSmsCode = (params: { account_no: string; scene: string }) =>
  post<DataWrapper<Api.SendSms>>({
    url: '/brand.php/front/sendCode',
    params,
  })

/**
 * 获取用户信息，权限信息
 */
export const requestGetUserInfo = () =>
  post<DataWrapper<Api.UserInfo>>({
    url: '/brand.php/brand/getAuthMenu',
    config: {
      ignoreCodeIntercept: true,
    },
  })

export const requestGetUserHobbies = () =>
  get<ListWrapper<Api.UserHobbies>>({
    url: '/brand.php/brand.brand_hobby/getHobby',
  })

export const requestGetBrangTagsList = () =>
  get<ListWrapper<Api.BrangTags>>({
    url: '/brand.php/works/getBrangTagsList',
  })

export const requestSaveHobby = (tags: string) =>
  get({
    url: '/brand.php/brand.brand_hobby/saveHobby',
    params: {
      sys_tags: tags,
    },
  })

export const requestGetAgentBrand = () =>
  get<DataWrapper<Api.AgentBrand>>({
    url: '/brand.php/agentbrand/getAgentBrandData',
  })

export const requestCheckAccount = (params: { account_no: string }) =>
  get<DataWrapper<Api.IsRegistered>>({
    url: '/brand.php/front/checkAccount',
    params,
  })
