import { useCounter, useIntervalFn } from '@vueuse/core'
import { computed } from 'vue'

/**
 * 倒计时
 * @param secord 开始时间
 * @param end 结束时间
 * @returns
 */
export const useCountdown = (max = 60, min = 0) => {
  const { count, dec, set } = useCounter(min, { min, max })
  const { pause, resume } = useIntervalFn(() => {
    if (isFinished.value) {
      pause()
    } else {
      dec()
    }
  }, 1000)

  const isFinished = computed(() => count.value === min)

  const restart = (secord = max) => {
    set(secord)
    resume()
  }

  pause()

  return {
    count,
    isFinished,
    restart,
  }
}
