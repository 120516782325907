<script lang="ts" setup>
  import { eventReport, useQidianService } from '@/composable'
  import { OpenServiceType } from '@/define'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import { useConcat } from './helper'

  const { closeConcat } = useConcat()
  const { onServiceClick } = useQidianService()
  const onRemember = () => {
    const config = localStorage.getItem('concatLocalConfig')
    const concatLocalConfig = config ? JSON.parse(config) : {}
    concatLocalConfig.remember = true
    localStorage.setItem('concatLocalConfig', JSON.stringify(concatLocalConfig))
    handleClose()
  }
  const handleClose = () => {
    closeConcat()
  }
  const openQidianService = () => {
    eventReport({
      event_id: 'pc_customer_service_click',
      parm: OpenServiceType.CONCATPOPUP,
    })
    onServiceClick()
    handleClose()
  }
</script>
<template>
  <div class="concat-modal">
    <div class="static-tools">
      <span class="remember" @click="onRemember">不再提醒</span>
      <img src="@images/common/icon-close.png" alt="" class="close-icon" @click="handleClose" />
    </div>
    <div class="concat-portrait"></div>
    <div class="main-content">
      <div class="confirm-wrap">
        <div class="confirm-text">联系我们</div>
        <div class="confirm-text">18659212343</div>
      </div>
      <div class="mark-wrap">
        <div class="mark-text">为您解答模板查找、编辑、定制及付费等问题</div>
        <div class="mark-text">客服在线时间：工作日 09:00~18:00</div>
      </div>
      <base-btn class="primary-btn" plain @click="openQidianService">在线咨询</base-btn>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .concat-modal {
    position: fixed;
    right: 100px;
    bottom: 16px;
    z-index: 100;
    width: 480px;
    height: 306px;
    background-color: #3b8cfd;
    border-radius: 4px;
    box-shadow: 0 8px 20px 0 rgb(161 178 219 / 30%);

    .static-tools {
      position: absolute;
      top: 17px;
      right: 20px;
      display: flex;
      align-items: center;
      color: #fff;

      .close-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      .remember {
        margin-right: 24px;
        font-size: 12px;
        letter-spacing: 1px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .concat-portrait {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100px;
      height: 100px;
      background: url('@images/common/bg-concat.png') no-repeat;
      background-size: cover;
      transform: translate(-50%, -50%);
    }

    .main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 70px;
      color: #fff;

      .confirm-wrap {
        margin-bottom: 16px;
        text-align: center;
      }

      .confirm-text {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
      }

      .mark-wrap {
        margin-bottom: 20px;
        text-align: center;
      }

      .mark-text {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .primary-btn {
      width: 252px;
      height: 40px;
      padding: 7px 0;
      color: #2b84ff;
      font-size: 16px;
      line-height: 24px;
      background-color: #fff;
    }
  }
</style>
